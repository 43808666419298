import React from "react";
import { useSelector } from "react-redux";
import { Trash, PencilSquare } from "react-bootstrap-icons";

const PaymentDisp = ({ payment, setShowModal, setType, setPayment }) => {
	const Modes = useSelector((state) => state.mode.modes);
	const Creds = useSelector((state) => state.cred.creds);

	const FindMode = Modes.find(
		(mode) =>
			parseInt(mode.linker) === parseInt(payment.mode) &&
			parseInt(payment.deleted) === 0
	) || { name: payment.mode === "Mpesa-Online" ? "Mpesa-Online" : "UnKnown" };

	const User = Creds.find(
		(cred) => parseInt(cred.linker) == parseInt(payment.credLinker)
	) || { firstname: "unknown" };

	return (
		<tr>
			<td>{FindMode.name}</td>
			<td>
				{payment.code} {payment.details}
			</td>
			<td> {new Date(parseInt(payment.linker)).toLocaleDateString("en-US")}</td>
			<td>{User.email}</td>
			<td>{payment.amount}</td>{" "}
			<td>
				<PencilSquare
					onClick={() => {
						setShowModal(true);
						setType("edit");
						setPayment({ ...payment });
					}}
					style={{ cursor: "pointer" }}
					className="text-info mx-2"
				/>

				<Trash
					onClick={() => {
						setShowModal(true);
						setType("delete");
						setPayment({ ...payment });
					}}
					style={{ cursor: "pointer" }}
					className="text-danger mx-2"
				/>
			</td>
		</tr>
	);
};

export default PaymentDisp;
