import React, { useState, useEffect } from "react";
import InvoiceCrud from "./InvoiceCrud";
import { useDispatch, useSelector } from "react-redux";
import NoInvoice from "./NoInvoice";
import { fetchCreds } from "../../reducers/CredSlice";
import InvoiceDisp from "./InvoiceDisp";
import { FetchInvoices } from "../../Network/InvoiceApi";
import { PlainList } from "flatlist-react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBarLeft } from "react-bootstrap-icons";
import GeneratePdf from "../../Utils/GeneratePdf";

const HouseInvoices = () => {
  useEffect(() => {
    FetchInvoices(User.token, ActiveCred.instLinker, dispatch, online);
  }, []);

  const params = useParams();
  const Navigate = useNavigate();

  const [ShowModal, setShowModal] = useState(false);
  const [Invoice, setInvoice] = useState({ name: "" });
  const [Type, setType] = useState("add");
  const [By, setBy] = useState("all");
  const [Day, setDay] = useState("all");
  const [Month, setMonth] = useState("all");
  const [Year, setYear] = useState("all");
  const [Bill, setBill] = useState("all");
  const [Tenant, setTenant] = useState("all");
  const [Yr, setYr] = useState("all");
  const [Mth, setMth] = useState("all");

  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Invoices = useSelector((state) => state.invoice.invoices);
  const Creds = useSelector((state) => state.cred.creds);
  const House =
    useSelector((state) => state.house.houses).find(
      (house) =>
        parseInt(house.deleted) === 0 &&
        parseInt(house.linker) === parseInt(params.houseLinker)
    ) || {};

  const Tenants = useSelector((state) => state.cred.creds).filter(
    (tenant) =>
      parseInt(tenant.deleted) === 0 &&
      (parseInt(tenant.tenant) === 1 || parseInt(tenant.tenant) === 2) &&
      parseInt(tenant.houseLinker) === parseInt(params.houseLinker)
  );

  const CurrentTenant = Tenants.find(
    (tenant) =>
      parseInt(tenant.deleted) === 0 &&
      parseInt(tenant.tenant) === 1 &&
      parseInt(tenant.houseLinker) === parseInt(params.houseLinker)
  );

  const Bills = useSelector((state) => state.bill.bills).filter(
    (bill) => parseInt(bill.deleted) === 0
  );

  const Payments = useSelector((state) => state.payment.payments);

  const FindUser = (linker) => {
    let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

    return cred || { email: "unknown" };
  };

  const InvoicePayments = (linker) =>
    Payments.filter(
      (payment) =>
        parseInt(payment.deleted) === 0 &&
        parseInt(payment.invoiceLinker) === parseInt(linker)
    );

  const SearchInvoice = Invoices.filter(
    (invoice) =>
      parseInt(invoice.deleted) === 0 &&
      (parseInt(invoice.billLinker) === parseInt(Bill) ||
        Bill === "all" ||
        invoice.billLinker === Bill) &&
      (parseInt(invoice.tenantLinker) === parseInt(Tenant) ||
        Tenant === "all") &&
      parseInt(invoice.houseLinker) === parseInt(params.houseLinker) &&
      (parseInt(invoice.month.split("-")[0]) === parseInt(Yr) ||
        Yr === "all") &&
      (parseInt(invoice.month.split("-")[1]) === parseInt(Mth) ||
        Mth === "all" ||
        Yr === "all") &&
      (parseInt(
        new Date(parseInt(invoice.linker)).toLocaleDateString().split("/")[2]
      ) === parseInt(Year) ||
        Year === "all") &&
      (parseInt(
        new Date(parseInt(invoice.linker)).toLocaleDateString().split("/")[0]
      ) === parseInt(Month) ||
        Year === "all" ||
        Month === "all") &&
      (parseInt(
        new Date(parseInt(invoice.linker)).toLocaleDateString().split("/")[1]
      ) === parseInt(Day) ||
        Year === "all" ||
        Month === "all" ||
        Day == "all") &&
      (parseInt(invoice.credLinker) === parseInt(By) || By === "all")
  )
    .map((invoice) => ({
      ...invoice,
      payments: InvoicePayments(invoice.linker).reduce(
        (a, b) => +a + +b.amount,
        0
      ),
    }))
    .sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  return (
    <div>
      <div id="house-invoices">
        <div className="d-flex justify-content-around mt-2">
          <div>
            {" "}
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={() => Navigate(-1)}
            >
              <ArrowBarLeft></ArrowBarLeft> Go Back
            </button>
          </div>
          <p className="text-center h6 text-primary text-capitalize">
            {House.name} Invoices
          </p>{" "}
          {CurrentTenant ? (
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={() => {
                setType("add");
                setShowModal(true);
                setInvoice({
                  linker: Date.now(),
                  trace: Date.now(),
                  name: "",
                  details: "",
                  amount: "",
                  code: "",
                  buildingLinker: "all",
                  houseLinker: params.houseLinker,
                  tenantLinker: CurrentTenant.linker,
                  deleted: "0",
                });
              }}
            >
              Add Invoice
            </button>
          ) : null}
        </div>

        <table className="table table-sm">
          <thead className="thead-dark">
            <tr>
              <th scope="colum">Tenant</th>
              <th scope="colum">Type</th>
              <th scope="colum">Month</th>
              <th scope="column">Inv</th>
              <th scope="column">Paid</th>
              <th scope="column">Bal</th>
              <th scope="column">Date</th>
              <th scope="column">By</th>
            </tr>
            <tr>
              <th scope="column">
                <select
                  className="form-control rounded"
                  value={Tenant}
                  onChange={(e) => setTenant(e.target.value)}
                >
                  <option value={"all"}>All Tenants</option>
                  {Tenants.map((tenant, index) => (
                    <option key={index} value={tenant.linker}>
                      {tenant.firstname} {tenant.lastname} {tenant.surname}
                    </option>
                  ))}
                </select>
              </th>
              <th scope="column">
                <select
                  className="form-control rounded"
                  value={Bill}
                  onChange={(e) => setBill(e.target.value)}
                >
                  <option value={"all"}>All Types</option>
                  <option value={"rent"}>Rent</option>
                  <option value={"deposit"}>Deposit</option>
                  {Bills.map((bill, index) => (
                    <option key={index} value={bill.linker}>
                      {bill.name}
                    </option>
                  ))}
                </select>
              </th>
              <th>
                <div className="d-flex">
                  {" "}
                  <select
                    className="form-control rounded"
                    value={Yr}
                    onChange={(e) => setYr(e.target.value)}
                  >
                    <option value={"all"}>All Yrs</option>
                    {[
                      ...new Set(
                        Invoices.map((invoice) => invoice.month.split("-")[0])
                      ),
                    ].map((yr) => (
                      <option key={yr} value={yr}>
                        {yr}
                      </option>
                    ))}
                  </select>
                  {Yr !== "all" ? (
                    <select
                      className="form-control rounded"
                      value={Mth}
                      onChange={(e) => setMth(e.target.value)}
                    >
                      <option value={"all"}>All Mths</option>
                      {[
                        ...new Set(
                          Invoices.map((invoice) => invoice.month.split("-")[1])
                        ),
                      ].map((mth) => (
                        <option key={mth} value={mth}>
                          {mth}
                        </option>
                      ))}
                    </select>
                  ) : null}
                </div>
              </th>

              <th>{SearchInvoice.reduce((a, b) => +a + +b.amount, 0)}</th>
              <th>{SearchInvoice.reduce((a, b) => +a + +b.payments, 0)}</th>
              <th>
                {SearchInvoice.reduce((a, b) => +a + +b.amount, 0) -
                  SearchInvoice.reduce((a, b) => +a + +b.payments, 0)}
              </th>
              <th>
                <div className="d-flex">
                  <select
                    className="form-control rounded"
                    value={Year}
                    onChange={(e) => setYear(e.target.value)}
                  >
                    <option value={"all"}>All Yrs</option>
                    {[
                      ...new Set(
                        Invoices.map(
                          (invoice) =>
                            new Date(parseInt(invoice.linker))
                              .toLocaleDateString()
                              .split("/")[2]
                        )
                      ),
                    ].map((yr) => (
                      <option key={yr} value={yr}>
                        {yr}
                      </option>
                    ))}
                  </select>
                  {Year !== "all" ? (
                    <select
                      className="form-control rounded"
                      value={Month}
                      onChange={(e) => setMonth(e.target.value)}
                    >
                      <option value={"all"}>All Mths</option>
                      {[
                        ...new Set(
                          Invoices.map(
                            (invoice) =>
                              new Date(parseInt(invoice.linker))
                                .toLocaleDateString()
                                .split("/")[0]
                          )
                        ),
                      ].map((mth) => (
                        <option key={mth} value={mth}>
                          {mth}
                        </option>
                      ))}
                    </select>
                  ) : null}
                  {Month !== "all" && Year !== "all" ? (
                    <select
                      className="form-control rounded"
                      value={Day}
                      onChange={(e) => setDay(e.target.value)}
                    >
                      <option value={"all"}>All Dates</option>
                      {[
                        ...new Set(
                          Invoices.map(
                            (invoice) =>
                              new Date(parseInt(invoice.linker))
                                .toLocaleDateString()
                                .split("/")[1]
                          )
                        ),
                      ].map((day) => (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                  ) : null}
                </div>
              </th>
              <th>
                <select
                  className="form-control rounded"
                  value={By}
                  onChange={(e) => setBy(e.target.value)}
                >
                  <option value={"all"}>All Staffs</option>
                  <PlainList
                    list={[
                      ...new Set(Invoices.map((invoice) => invoice.credLinker)),
                    ]}
                    renderOnScroll
                    renderItem={(staff) => (
                      <option value={staff} key={staff}>
                        {FindUser(staff).email}
                      </option>
                    )}
                  />
                </select>
              </th>
            </tr>
          </thead>
          {SearchInvoice.length > 0 ? (
            <tbody>
              <PlainList
                list={SearchInvoice}
                renderOnScroll
                renderItem={(invoice) => (
                  <InvoiceDisp
                    setShowModal={setShowModal}
                    ShowModal={ShowModal}
                    invoice={invoice}
                    setInvoice={setInvoice}
                    type={Type}
                    setType={setType}
                    key={invoice.linker}
                  ></InvoiceDisp>
                )}
              />
            </tbody>
          ) : (
            <NoInvoice></NoInvoice>
          )}
        </table>
      </div>
      <GeneratePdf
        id={"house-invoices"}
        name={`${House.name}-invoices`}
      ></GeneratePdf>
      <InvoiceCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        invoice={Invoice}
        setInvoice={setInvoice}
        type={Type}
      ></InvoiceCrud>
    </div>
  );
};

export default HouseInvoices;
