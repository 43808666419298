import swal from "sweetalert";
import { addOwner, fetchOwners, updateOwner } from "../reducers/OwnerSlice";
import { ApiUrl } from "./Urls";

export const FetchOwners = async (
  token,
  instLinker,
  dispatch,
  online,
  from = "fetch",
  payload = {}
) => {
  if (from === "fetch") {
    FetchLiveOwners(token, instLinker, online, dispatch);
  } else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
    dispatch(updateOwner(payload));
  }
};

export const addLiveOwner = (owner, instLinker, token, dispatch) => {
  return fetch(`${ApiUrl}/owner/add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...owner,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 201) {
        dispatch(updateOwner(res.owner));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const updateLiveOwner = (owner, token, dispatch) => {
  return fetch(`${ApiUrl}/owner/edit`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...owner,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(updateOwner(res.owner));
      } else {
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const FetchLiveOwners = (token, instLinker, online, dispatch) => {
  fetch(`${ApiUrl}/owner/get`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      online,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchOwners(res.owners));
      }
    })
    .catch(() => {});
};
