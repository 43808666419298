import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PlainList } from "flatlist-react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBarLeft } from "react-bootstrap-icons";
import PaymentDisp from "./PaymentDisp";
import PaymentCrud from "../Payments/PaymentCrud";
import GeneratePdf from "../../Utils/GeneratePdf";

const InvoicePayments = () => {
	const params = useParams();
	const Navigate = useNavigate();

	const [ShowModal, setShowModal] = useState(false);
	const [Payment, setPayment] = useState({ name: "" });
	const [Type, setType] = useState("add");

	const Invoices = useSelector((state) => state.invoice.invoices);
	const Bills = useSelector((state) => state.bill.bills).filter(
		(bill) => parseInt(bill.deleted) === 0
	);
	const Creds = useSelector((state) => state.cred.creds);
	const Payments = useSelector((state) => state.payment.payments).filter(
		(payment) =>
			parseInt(payment.invoiceLinker) === parseInt(params.invoiceLinker) &&
			parseInt(payment.deleted) === 0
	);

	const Invoice =
		Invoices.find(
			(invoice) => parseInt(invoice.linker) === parseInt(params.invoiceLinker)
		) || {};
	const User = Creds.find(
		(cred) => parseInt(cred.linker) == parseInt(Invoice.credLinker)
	) || { firstname: "unknown" };
	const Tenant =
		Creds.find(
			(tenant) => parseInt(Invoice.tenantLinker) === parseInt(tenant.linker)
		) || {};
	const House =
		useSelector((state) => state.house.houses).find(
			(house) => parseInt(Invoice.houseLinker) === parseInt(house.linker)
		) || {};
	const FindBill = Bills.find(
		(bill) => parseInt(bill.linker) === parseInt(Invoice.billLinker)
	) || { name: "unKnown" };

	return (
		<div>
			{" "}
			<div id="invoice-payments">
				<div className="d-flex justify-content-around">
					<div>
						{" "}
						<button
							className="btn btn-sm btn-outline-secondary"
							onClick={() => Navigate(-1)}
						>
							<ArrowBarLeft></ArrowBarLeft> Go Back
						</button>
					</div>
					<div>
						{" "}
						<p className="text-capitalize text-center m-0 p-0 h5">
							{Tenant.firstname} {Tenant.lastname} {Tenant.surname}
						</p>
						<p className="text-capitalize text-center m-0 p-0 h6">
							{House.name}
						</p>
					</div>
				</div>
				<div>
					<table className="table table-sm">
						<thead>
							<tr>
								<th className="text-capitalize">
									{Invoice.billLinker === "rent"
										? "rent"
										: Invoice.billLinker === "deposit"
										? "deposit"
										: FindBill.name}{" "}
									Invoice
								</th>
								<th>{Invoice.month}</th>
								<th>
									{new Date(parseInt(Invoice.linker)).toLocaleDateString(
										"en-US"
									)}
								</th>
								<th>{User.email}</th>
								<th>{Invoice.amount}</th>
								<th>
									{parseInt(Invoice.amount) -
										Payments.reduce((a, b) => +a + +b.amount, 0) >
									0 ? (
										<button
											className="btn btn-sm btn-outline-primary"
											onClick={() => {
												setType("add");
												setShowModal(true);
												setPayment({
													linker: Date.now(),
													trace: Date.now(),
													name: Invoice.billLinker,
													details: "",
													amount: "",
													code: "",
													invoiceLinker: params.invoiceLinker,
													deleted: "0",
												});
											}}
										>
											Pay
										</button>
									) : (
										<span className="text-info"> Settled</span>
									)}{" "}
								</th>
							</tr>
							<tr>
								<th>Mode</th>
								<th>Details</th>
								<th>Date</th>
								<th>User</th>
								<th>Sum:{Payments.reduce((a, b) => +a + +b.amount, 0)} </th>
								<th>
									Bal :{" "}
									{parseInt(Invoice.amount) -
										Payments.reduce((a, b) => +a + +b.amount, 0)}
								</th>
							</tr>
						</thead>
						<tbody>
							<PlainList
								list={Payments}
								renderOnScroll
								renderItem={(payment) => (
									<PaymentDisp
										key={payment.linker}
										payment={payment}
										setShowModal={setShowModal}
										setPayment={setPayment}
										setType={setType}
									></PaymentDisp>
								)}
							/>
						</tbody>
					</table>
				</div>
			</div>
			<GeneratePdf
				id="invoice-payments"
				name={`${House.name}-${Tenant.firstname}-${Tenant.lastname}-${Tenant.surname}-invoice`}
			></GeneratePdf>
			<PaymentCrud
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				payment={Payment}
				setPayment={setPayment}
				type={Type}
				tenant={Tenant}
			></PaymentCrud>
		</div>
	);
};

export default InvoicePayments;
