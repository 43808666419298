import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Modal } from "react-bootstrap";
import { SendMessages } from "../../../Network/MessageApi";
import PurchaseModal from "./PurchaseModal";

const SingleSms = ({ Tenant, SmsModal, setSmsModal }) => {
	//redux dispatch
	const dispatch = useDispatch();

	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const inst = useSelector((state) => state.inst.activeInst);
	const messages = useSelector((state) => state.message.messages)
		.filter((message) => message.id)
		.sort((a, b) => a.id - b.id);
	const [Message, setMessage] = useState("");
	const [Loading, setLoading] = useState(false);
	const [ShowModal, setShowModal] = useState(false);

	const HandleSend = (e) => {
		e.preventDefault();
		//ensure tenat has contact then create message object
		let Messages = [Tenant]
			.filter((tenant) => !/^$/.test(tenant.contact) && tenant.contact)
			.map((tenant) => ({
				mobile: tenant.contact
					.toString()
					.replace(/^\+/, "")
					.replace(/^0/, "254"),
				message: `${inst.name
					.split(" ")
					.map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
					.join(" ")}: ${Message}`,
			}));

		// group messages in chunks of 20
		let Chunks = Messages.reduce(
			(acc, _, i) => (i % 20 ? acc : [...acc, Messages.slice(i, i + 20)]),
			[]
		);

		//maps chunks and send each chunk
		Chunks.map((chunk) =>
			SendMessages(
				User.token,
				ActiveCred.instLinker,
				chunk,
				dispatch,
				setLoading
			)
		);
	};
	return (
		<>
			<Modal
				show={SmsModal}
				onHide={() => setSmsModal(false)}
				backdrop="static"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<div className="d-flex justify-content-between">
							<p className="text-capitalize text-center">
								Send SMS Message To {Tenant.firstname} <br />{" "}
								<e className="text-danger">
									{1 >
									(messages.length > 0 ? messages[messages.length - 1].cf : 0)
										? `{You Have 0 Messages; Purchase}`
										: null}
								</e>
							</p>{" "}
							<button
								className="btn btn-outline-success rounded"
								onClick={() => setShowModal(true)}
							>
								Purchase Messages
							</button>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={HandleSend}>
						<div className="form-group">
							<label>Message:</label>
							<textarea
								required
								placeholder="Type message..."
								value={Message}
								onChange={(e) => setMessage(e.target.value)}
								className="form-control rounded"
							/>
						</div>

						<div className="d-flex justify-content-around mb-2">
							{Loading ? (
								<span className="spinner-border text-primary"></span>
							) : 1 >
							  (messages.length > 0 ? messages[messages.length - 1].cf : 0) ? (
								<span className="text-center text-danger h6">
									{" "}
									{`{No Messages; Purchase Above}`}
								</span>
							) : (
								<button type="submit" className="btn btn-success rounded-pill">
									Send Sms
								</button>
							)}
						</div>
					</Form>
				</Modal.Body>
			</Modal>
			<PurchaseModal
				ShowModal={ShowModal}
				setShowModal={setShowModal}
			></PurchaseModal>
		</>
	);
};

export default SingleSms;
