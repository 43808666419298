import React from "react";
import { Dropdown } from "react-bootstrap";
import {
	Trash,
	PencilSquare,
	CashCoin,
	Envelope,
	ArrowBarLeft,
} from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const TenantDisp = ({
	tenant,
	setShowModal,
	setSmsModal,
	setTenant,
	setType,
}) => {
	const navigate = useNavigate();
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const inst = useSelector((state) => state.inst.activeInst);
	const Houses = useSelector((state) => state.house.houses).filter(
		(house) => parseInt(house.deleted) === 0
	);

	const Buildings = useSelector((state) => state.building.buildings).filter(
		(building) => parseInt(building.deleted) === 0
	);

	const FindHouse = Houses.find(
		(house) => parseInt(house.linker) === parseInt(tenant.houseLinker)
	) || { name: "unknown", buildingLinker: "all" };

	const FindBuilding = (linker) =>
		Buildings.find(
			(building) => parseInt(building.linker) === parseInt(linker)
		) || { name: "unknown" };

	return (
		<tr>
			<td className="text-wrap">
				{tenant.firstname} {tenant.lastname} {tenant.surname}
			</td>
			<td>{tenant.email}</td>
			<td>{tenant.contact}</td>
			<td>
				{tenant.tenant == 1 ? (
					<span className="text-success">Current</span>
				) : (
					<span className="text-danger">Exited</span>
				)}
			</td>
			<td>{FindBuilding(FindHouse.buildingLinker).name}</td>
			<td>{FindHouse.name}</td>
			<td>{tenant.invoices}</td>
			<td>{tenant.payments}</td>
			<td>{tenant.invoices - tenant.payments}</td>
			<td>
				<Dropdown variant="transparent">
					<Dropdown.Toggle
						variant="transparent"
						className="text-left text-wrap text-primary"
					>
						Update Tenant
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{parseInt(tenant.tenant) === 1 ? (
							<>
								<Dropdown.Item
									onClick={() => {
										setShowModal(true);
										setType("edit");
										setTenant({
											...tenant,
											buildingLinker: FindHouse.buildingLinker,
											house: FindHouse,
										});
									}}
									style={{ cursor: "pointer" }}
									className="text-info mx-1"
								>
									<PencilSquare />
									Edit Tenant
								</Dropdown.Item>
								<Dropdown.Item
									className="btn-link text-primary mx-1"
									onClick={() => {
										setShowModal(true);
										setType("exit");
										setTenant({
											...tenant,
											buildingLinker: FindHouse.buildingLinker,
											house: FindHouse,
										});
									}}
									style={{ cursor: "pointer" }}
								>
									<ArrowBarLeft />
									Exit Tenant
								</Dropdown.Item>
							</>
						) : null}
						{parseInt(ActiveCred.admin) === 1 ? (
							<Dropdown.Item
								className="text-danger mx-1"
								onClick={() => {
									setShowModal(true);
									setType("delete");
									setTenant({
										...tenant,
										buildingLinker: FindHouse.buildingLinker,
										house: FindHouse,
									});
								}}
								style={{ cursor: "pointer" }}
							>
								<Trash />
								Delete Tenant
							</Dropdown.Item>
						) : null}
						<Dropdown.Item
							onClick={() => {
								window.scroll(0, 0);
								navigate(`house-invoices/${tenant.houseLinker}`);
							}}
							style={{ cursor: "pointer" }}
							className="text-primary mx-1"
						>
							<CashCoin />
							Tenant Invoice & Pay
						</Dropdown.Item>

						{inst.sub === "Kenya" ? (
							<Dropdown.Item
								onClick={() => {
									setSmsModal(true);
									setTenant({
										...tenant,
										buildingLinker: FindHouse.buildingLinker,
									});
								}}
								className="text-primary mx-1"
								style={{ cursor: "pointer" }}
							>
								<Envelope />
								Sms Tenant
							</Dropdown.Item>
						) : null}
					</Dropdown.Menu>
				</Dropdown>
			</td>
		</tr>
	);
};

export default TenantDisp;
