import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addLiveBuilding,
  updateLiveBuilding,
} from "../../../Network/BuildingApi";

const BuildingCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const User = useSelector((state) => state.auth.user);
  const Owners = useSelector((state) => state.owner.owners);
  const [Loading, setLoading] = useState(false);

  //add building
  const AddBuilding = async () => {
    let linker = Date.now();
    await addLiveBuilding(
      {
        details: props.building.details,
        linker,
        name: props.building.name,
        ownerLinker: props.building.ownerLinker,
        instLinker: ActiveCred.instLinker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: 0,
        trace: linker,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );

    props.setBuilding({
      ...props.building,
      name: "",
      details: "",
      ownerLinker: "",
    });
  };

  //edit building
  const EditBuilding = async () => {
    let { credLinker, trace, deleted } = props.building;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveBuilding(
      { ...props.building, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add") {
      await AddBuilding();
    } else {
      await EditBuilding();
    }
    setLoading(false);
    props.setShowModal(false);
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">{props.type} Building</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          <div className="form-group">
            <label className="mb-2 text-capitalize">Building Name:</label>
            <input
              className="rounded form-control"
              placeholder={`enter building name`}
              value={props.building.name}
              onChange={(e) =>
                props.setBuilding({
                  ...props.building,
                  name: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          {Owners.length > 0 ? (
            <div className="form-group">
              <label className="mb-2 text-capitalize">Building Owner:</label>
              <select
                className="rounded form-control"
                placeholder={`enter building`}
                value={props.building.ownerLinker}
                onChange={(e) =>
                  props.setBuilding({
                    ...props.building,
                    ownerLinker: e.target.value,
                  })
                }
                readOnly={props.type === "delete" ? true : false}
              >
                <option value={""}>Select Building Owner</option>
                {Owners.map((owner) => (
                  <option value={owner.linker} key={owner.linker}>
                    {owner.name}
                  </option>
                ))}
              </select>
              <hr />
            </div>
          ) : null}

          <div className="form-group">
            <label className="mb-2 text-capitalize">Building Details:</label>
            <textarea
              className="rounded form-control"
              placeholder={`enter building details`}
              value={props.building.details}
              onChange={(e) =>
                props.setBuilding({
                  ...props.building,
                  details: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : props.type === "delete" ? (
              <Button variant="danger" type="submit">
                Delete
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BuildingCrud;
