import React from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CashCoin } from "react-bootstrap-icons";

const MonthlyDisp = ({ house }) => {
  const navigate = useNavigate();
  return (
    <tr>
      <td>{house.name}</td>
      <td>
        {house.tenant.linker ? (
          <Dropdown>
            <Dropdown.Toggle
              variant="transparent"
              style={{ margin: 0, padding: 0 }}
            >
              {house.tenant.firstname} {house.tenant.lastname}{" "}
              {house.tenant.surname}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <p>
                  <strong>Contact :</strong>
                  <em>{house.tenant.contact}</em>
                </p>
              </Dropdown.Item>

              <Dropdown.Item>
                <p>
                  <strong>Email :</strong>
                  <em>{house.tenant.email}</em>
                </p>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <span className="text-danger">vacant</span>
        )}
      </td>
      <td>{house.invoices}</td>
      <td>{house.payments}</td>
      <td>{house.invoices - house.payments}</td>
      <td>
        <CashCoin
          onClick={() => {
            navigate(`house-invoices/${house.linker}`);
          }}
          style={{ cursor: "pointer" }}
          className="text-primary mx-2"
        />
      </td>
    </tr>
  );
};

export default MonthlyDisp;
