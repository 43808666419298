import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { ApiUrl } from "../../Network/Urls";
import { useSelector } from "react-redux";
import swal from "sweetalert";

const MpesaPayModal = ({ invoice, ShowModal, setShowModal, house }) => {
	const [Phone, setPhone] = useState("");
	const [Amount, setAmount] = useState("");
	const [Loading, setLoading] = useState(false);

	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const User = useSelector((state) => state.auth.user);

	const HandleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		fetch(`${ApiUrl}/client-pay/pay`, {
			method: "POST",
			headers: {
				"content-type": "application/json",
				authorization: `bearer ${User.token}`,
			},
			body: JSON.stringify({
				instLinker: ActiveCred.instLinker,
				invoiceLinker: invoice.linker,
				phone: parseInt(
					Phone.toString().replace(/^\+/, "").replace(/^0/, "254")
				),
				houseNo: house.name,
				amount: Amount,
				details: `${invoice.month}`,
			}),
		})
			.then((res) => res.json())
			.then((res) => {
				setLoading(false);
				if (res.status === 200) {
					swal({
						title: "Transaction Initiated",
						text: "Check your phone to complete it",
						icon: "success",
						timer: 3000,
					});
					setShowModal(false);
				} else {
					swal({
						title: "Failed to connect",
						text: "Please try again",
						icon: "warning",
						timer: 3000,
					});
				}
			})
			.catch(() => {
				setLoading(false);
				swal({
					title: "Failed to connect",
					text: "Please try again",
					icon: "warning",
					timer: 3000,
				});
			});
	};

	return (
		<Modal
			show={ShowModal}
			onHide={() => setShowModal(false)}
			backdrop="static"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<p className="text-capitalize text-center text-success text-decoration-underline">
						Pay Through Mpesa <br />
						{`{Ensure Your Phone Is Unlocked}`}
					</p>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={HandleSubmit}>
					<div className="form-group">
						<label className="mb-2 text-capitalize">
							Phone Number To Pay From
						</label>

						<input
							className="rounded form-control"
							placeholder={`enter phone number`}
							value={Phone}
							onChange={(e) => setPhone(e.target.value)}
							type="number"
							required
						/>
						<hr />
					</div>

					<div className="form-group">
						<label className="mb-2 text-capitalize"> Amount:</label>
						<input
							className="rounded form-control"
							placeholder={`enter amount`}
							value={Amount}
							onChange={(e) => setAmount(e.target.value)}
							type="number"
							min={10}
						/>
						<hr />
					</div>

					<div className="d-flex justify-content-around mb-2">
						{Loading ? (
							<span className="spinner-border text-primary"></span>
						) : (
							<Button variant="primary" type="submit">
								Send
							</Button>
						)}
						<Button
							variant="secondary"
							type="button"
							onClick={() => setShowModal(false)}
						>
							Cancel
						</Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default MpesaPayModal;
