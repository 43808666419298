import React, { useState, useEffect } from "react";
import {
  PersonAdd,
  Trash,
  PencilSquare,
  BookmarkPlus,
} from "react-bootstrap-icons";
import StaffCrud from "./StaffCrud";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FetchStaffs } from "../../../Network/CredApi";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../../Utils/GeneratePdf";
import { FetchLivePayrollCats } from "../../../Network/PayrollCatApi";
import { FetchLivePayrollItems } from "../../../Network/PayrollItemApi";
import { FetchLivePayrollEntries } from "../../../Network/PayrollEntryApi";
import BulkPayslips from "../Payroll/BulkPayslips";
const Staffs = () => {
  useEffect(() => {
    FetchStaffs(ActiveCred.instLinker, User.token, dispatch, online);
    FetchLivePayrollCats(User.token, ActiveCred.instLinker, online, dispatch);
    FetchLivePayrollItems(User.token, ActiveCred.instLinker, online, dispatch);
    FetchLivePayrollEntries(
      User.token,
      ActiveCred.instLinker,
      online,
      dispatch
    );
  }, []);

  //redux dispatch
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const inst = useSelector((state) => state.inst.activeInst);

  const [Search, setSearch] = useState("");
  const [ShowModal, setShowModal] = useState(false);
  const [PayslipModal, setPayslipModal] = useState(false);
  const [Staff, setStaff] = useState({
    firstname: "",
    lastname: "",
    surname: "",
    staffReg: "",
    contact: "",
    email: "",
    admin: "",
    staff: "",
  });
  const [Type, setType] = useState("add");

  const Staffs = useSelector((state) => state.cred.creds)
    .filter(
      (staff) =>
        staff.credLinker != "0" &&
        parseInt(staff.deleted) === 0 &&
        (parseInt(staff.admin) === 1 || parseInt(staff.staff) === 1) &&
        ((staff.staffReg || "").toLowerCase().search(Search.toLowerCase()) >
          -1 ||
          (staff.firstname || "").toLowerCase().search(Search.toLowerCase()) >
            -1 ||
          (staff.lastname || "").toLowerCase().search(Search.toLowerCase()) >
            -1 ||
          (staff.surname || "").toLowerCase().search(Search.toLowerCase()) >
            -1 ||
          (staff.email || "").toLowerCase().search(Search.toLowerCase()) > -1 ||
          (staff.contact || "").toLowerCase().search(Search.toLowerCase()) >
            -1 ||
          Search === "") &&
        parseInt(staff.linker) !== parseInt(inst.linker)
    )
    .sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  const Items = useSelector((state) => state.payrollItem.payrollItems).filter(
    (item) =>
      parseInt(item.deleted) === 0 &&
      Staffs.some(
        (staff) => parseInt(staff.linker) === parseInt(item.staffLinker)
      )
  );

  return (
    <div>
      <div className="d-flex justify-content-around">
        <div>
          <p className="text-center h6 text-primary text-capitalize">
            {Staffs.length} Staffs
          </p>
        </div>
        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              setShowModal(true);
              setType("add");
              setStaff({
                firstname: "",
                lastname: "",
                surname: "",
                staffReg: "",
                contact: "",
                email: "",
                admin: "",
                medical: "",
                finance: "",
              });
            }}
          >
            <PersonAdd /> Add Staff
          </button>
        </div>
        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              setSearch("");
              setPayslipModal(true);
            }}
          >
            <BookmarkPlus /> Generate Payslips
          </button>
        </div>
      </div>
      <table className="table table-sm" id="staffs">
        <thead className="thead-dark">
          <tr>
            <td>
              <input
                type={"text"}
                placeholder={`Search Staff`}
                value={Search}
                onChange={(e) => setSearch(e.target.value)}
                className="rounded form-control"
              />
            </td>
            <td>Name</td>

            <td>email</td>
            <td>contact</td>
            <td>Admin</td>
            <td>Staff</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <PlainList
            list={Staffs}
            renderOnScroll
            renderItem={(staff) => (
              <tr key={staff.index}>
                <td>{staff.staffReg}</td>
                <td>
                  {staff.firstname} {staff.lastname} {staff.surname}
                </td>
                <td>{staff.email}</td>
                <td>{staff.contact}</td>
                <td>
                  {staff.admin == 1 ? (
                    <span className="text-success">Active</span>
                  ) : (
                    <span className="text-secondary">InActive</span>
                  )}
                </td>
                <td>
                  {staff.staff == 1 ? (
                    <span className="text-success">Active</span>
                  ) : (
                    <span className="text-secondary">InActive</span>
                  )}
                </td>
                <td>
                  <button
                    className="btn btn-sm btn-outline-info mx-1"
                    onClick={() => {
                      setShowModal(true);
                      setType("edit");
                      setStaff({ ...staff });
                    }}
                  >
                    {" "}
                    <PencilSquare style={{ cursor: "pointer" }} />
                  </button>
                  <button
                    className="btn btn-sm btn-outline-danger mx-1"
                    onClick={() => {
                      setShowModal(true);
                      setType("delete");
                      setStaff({ ...staff });
                    }}
                  >
                    {" "}
                    <Trash style={{ cursor: "pointer" }} />
                  </button>
                  <span
                    className="btn-link text-primary mx-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(
                        `payroll-items/${staff.firstname}/${staff.staffReg}/${staff.linker}`
                      );
                      window.scroll(0, 0);
                    }}
                  >
                    Payroll
                  </span>
                </td>
              </tr>
            )}
          />
        </tbody>
      </table>
      <StaffCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        staff={Staff}
        setStaff={setStaff}
        type={Type}
      ></StaffCrud>
      <BulkPayslips
        items={Items}
        setShowModal={setPayslipModal}
        ShowModal={PayslipModal}
      ></BulkPayslips>
      <div className="d-flex justify-content-center">
        <GeneratePdf id={"staffs"} name={`staffs`}></GeneratePdf>
      </div>
    </div>
  );
};

export default Staffs;
