import { createSlice } from "@reduxjs/toolkit";

export const OwnerSlice = createSlice({
  name: "owner",
  initialState: {
    owners: [],
  },
  reducers: {
    fetchOwners: (state, action) => {
      state.owners = action.payload;
    },
    addOwner: (state, action) => {
      state.owners = [action.payload, ...state.owners];
    },
    updateOwner: (state, action) => {
      let index = state.owners.findIndex(
        (owner) => parseInt(owner.linker) === parseInt(action.payload.linker)
      );

      let prevOwners = [...state.owners];

      prevOwners.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.owners = prevOwners;
    },
    deleteOwner: (state, action) => {
      let newOwners = state.owners.filter(
        (owner) => parseInt(owner.linker) !== parseInt(action.payload.linker)
      );
      state.owners = newOwners;
    },
  },
});

export const { fetchOwners, addOwner, updateOwner, deleteOwner } =
  OwnerSlice.actions;

export default OwnerSlice.reducer;
