import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setActiveCred } from "../../reducers/CredSlice";
import { setActiveInst } from "../../reducers/InstSlice";
import { updateOnline } from "../../reducers/OnlineSlice";

const Insts = () => {
	const insts = useSelector((state) => state.inst.insts);
	const creds = useSelector((state) => state.cred.creds);

	//redux dispatch
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const Navigate = (inst) => {
		let Cred = creds.find(
			(cred) => parseInt(cred.instLinker) === parseInt(inst.linker)
		);
		dispatch(setActiveCred(Cred ? Cred : {}));
		dispatch(setActiveInst(inst));
		dispatch(updateOnline(0));

		navigate(`/${inst.name}`);
	};
	return insts.map((inst, index) => (
		<div
			className="col-6 col-md-4 col-lg-3"
			key={index}
			onClick={() => Navigate(inst)}
		>
			<div className="dashboard">
				<div className="d-flex justify-content-center">
					<span className="dash-icon text-capitalize">{inst.name[0]}</span>
				</div>

				<p className="text-center text-wrap dash-text  "> {inst.name}</p>
			</div>
		</div>
	));
};

export default Insts;
