import { createSlice } from "@reduxjs/toolkit";

export const BillSlice = createSlice({
  name: "bill",
  initialState: {
    bills: [],
  },
  reducers: {
    fetchBills: (state, action) => {
      state.bills = action.payload;
    },
    addBill: (state, action) => {
      state.bills = [action.payload, ...state.bills];
    },
    updateBill: (state, action) => {
      let index = state.bills.findIndex(
        (bill) => parseInt(bill.linker) === parseInt(action.payload.linker)
      );

      let prevBills = [...state.bills];

      prevBills.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.bills = prevBills;
    },
    deleteBill: (state, action) => {
      let newBills = state.bills.filter(
        (bill) => parseInt(bill.linker) !== parseInt(action.payload.linker)
      );
      state.bills = newBills;
    },
  },
});

export const { fetchBills, addBill, updateBill, deleteBill } =
  BillSlice.actions;

export default BillSlice.reducer;
