import React from "react";
import { useSelector } from "react-redux";
import { Trash, PencilSquare } from "react-bootstrap-icons";

const PaymentDisp = ({
	payment,
	setShowModal,
	setType,
	setPayment,
	setActiveTenant,
	invoice,
}) => {
	const Creds = useSelector((state) => state.cred.creds);
	const Houses = useSelector((state) => state.house.houses).filter(
		(house) => parseInt(house.deleted) === 0
	);
	const Tenants = useSelector((state) => state.cred.creds).filter(
		(tenant) => parseInt(tenant.deleted) === 0
	);

	const Modes = useSelector((state) => state.mode.modes).filter(
		(mode) => parseInt(mode.deleted) === 0
	);

	const Bills = useSelector((state) => state.bill.bills).filter(
		(bill) => parseInt(bill.deleted) === 0
	);
	const Buildings = useSelector((state) => state.building.buildings).filter(
		(building) => parseInt(building.deleted) === 0
	);

	const FindUser = (linker) => {
		let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

		return cred || { firstname: "unknown" };
	};

	const FindHouse = Houses.find(
		(house) => parseInt(house.linker) === parseInt(invoice.houseLinker)
	) || { name: "unKnown" };

	const FindBuilding = Buildings.find(
		(building) =>
			parseInt(building.linker) === parseInt(FindHouse.buildingLinker)
	) || { name: "unKnown" };

	const FindTenant = Tenants.find(
		(tenant) => parseInt(tenant.linker) === parseInt(invoice.tenantLinker)
	) || { firstname: "unknown" };

	const FindBill = Bills.find(
		(bill) => parseInt(bill.linker) === parseInt(payment.name)
	) || { name: "unKnown" };

	const FindMode = Modes.find(
		(mode) => parseInt(mode.linker) === parseInt(payment.mode)
	) || { name: payment.mode === "Mpesa-Online" ? "Mpesa-Online" : "UnKnown" };

	return (
		<tr className="item">
			<td>{FindBuilding.name}</td>
			<td>{FindHouse.name}</td>
			<td>
				{FindTenant.firstname} {FindTenant.lastname} {FindTenant.surname}
			</td>
			<td>
				{payment.name === "rent"
					? "rent"
					: payment.name === "deposit"
					? "deposit"
					: FindBill.name}
			</td>
			<td>{invoice.month}</td>
			<td>{FindMode.name}</td>
			<td>
				{payment.code} {payment.details}
			</td>
			<td>{payment.amount}</td>
			<td>{new Date(parseInt(payment.linker)).toLocaleDateString("en-US")}</td>
			<td>{FindUser(payment.credLinker).email}</td>
			<td>
				<PencilSquare
					onClick={() => {
						setShowModal(true);
						setType("edit");
						setPayment({ ...payment });
						setActiveTenant(FindTenant);
					}}
					style={{ cursor: "pointer" }}
					className="text-info mx-2"
				/>

				<Trash
					onClick={() => {
						setShowModal(true);
						setType("delete");
						setPayment({ ...payment });
						setActiveTenant(FindTenant);
					}}
					style={{ cursor: "pointer" }}
					className="text-danger mx-2"
				/>
			</td>
		</tr>
	);
};

export default PaymentDisp;
