import React, { useEffect, useState } from "react";
import { ApiUrl } from "../../Network/Urls";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { ArrowClockwise } from "react-bootstrap-icons";
import MpesaPayModal from "./MpesaPayModal";

const Client = () => {
	useEffect(() => {
		FetchTransactions("invoices", []);
		FetchTransactions("bills", []);
		FetchTransactions("house", ActiveCred.houseLinker);
	}, []);

	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);

	const [Bills, setBills] = useState([]);
	const [Invoices, setInvoices] = useState([]);
	const [Payments, setPayments] = useState([]);
	const [Loading, setLoading] = useState(false);
	const [Details, setDetails] = useState(false);
	const [ToShow, setToShow] = useState(0);
	const [Invoice, setInvoice] = useState({});
	const [House, setHouse] = useState({});
	const [ShowModal, setShowModal] = useState(false);

	const GetBill = (billLinker) => {
		let bill = Bills.find(
			(bill) => parseInt(billLinker) === parseInt(bill.linker)
		);
		return bill || { name: billLinker };
	};

	const FetchTransactions = (link, data) => {
		setLoading(true);
		fetch(`${ApiUrl}/client/${link}`, {
			method: "POST",
			headers: {
				"content-type": "application/json",
				authorization: `bearer ${User.token}`,
			},
			body: JSON.stringify({
				instLinker: ActiveCred.instLinker,
				invoiceLinkers: data,
				houseLinker: data,
			}),
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.status === 200) {
					if (link === "bills") {
						setBills(res.bills);
					}
					if (link === "house") {
						setHouse(res.house);
					}
					if (link === "invoices") {
						setInvoices(
							res.invoices.map((invoice) => ({
								...invoice,
								transactionType: "invoice",
							}))
						);
						FetchTransactions(
							"payments",
							res.invoices.map((entry) => entry.linker)
						);
					}
					if (link === "payments") {
						setPayments(
							res.payments.map((payment) => ({
								...payment,
								transactionType: "payment",
							}))
						);
						setLoading(false);
					}
				} else {
					setLoading(false);
					swal({
						title: "Server Error",
						text: "connect to network and refresh",
						icon: "warning",
						timer: 3000,
					});
				}
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
				swal({
					title: "Netwwork Error",
					text: "connect to network and refresh",
					icon: "warning",
					timer: 3000,
				});
			});
	};
	return (
		<div>
			<p className="h6 text-center">
				{Loading ? (
					<span className="text-primary spinner-border"></span>
				) : (
					<ArrowClockwise
						style={{ cursor: "pointer" }}
						className="h3 text-primary"
						onClick={() => FetchTransactions("invoices", [])}
					></ArrowClockwise>
				)}
			</p>
			<table className="table table-sm">
				<thead>
					<tr>
						<th></th>
						<th></th>
						<th>Inv</th>
						<th>Paid</th>

						<th>Bal</th>
						<th></th>
						<th></th>
						<th></th>
					</tr>
					<tr>
						<th>Month</th>
						<th>Type</th>
						<th>{Invoices.reduce((a, b) => +a + +b.amount, 0)}</th>{" "}
						<th>{Payments.reduce((a, b) => +a + +b.amount, 0)}</th>
						<th>
							{Invoices.reduce((a, b) => +a + +b.amount, 0) -
								Payments.reduce((a, b) => +a + +b.amount, 0)}
						</th>
						<th>Date</th>
						<th></th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{Invoices.sort((a, b) => b.linker - a.linker).map((invoice) => (
						<tr key={invoice.linker}>
							<td>{invoice.month}</td>
							<td>{GetBill(invoice.billLinker).name}</td>
							<td>{invoice.amount}</td>
							<td
								onClick={() => {
									setDetails(true);
									setToShow(ToShow === invoice.linker ? 0 : invoice.linker);
								}}
							>
								<span
									style={{ cursor: "pointer" }}
									className="text-success btn-link"
								>
									{Payments.filter(
										(payment) =>
											parseInt(payment.invoiceLinker) ===
											parseInt(invoice.linker)
									).reduce((a, b) => +a + +b.amount, 0)}
								</span>{" "}
								{Details === true && ToShow === invoice.linker ? (
									<table className="table-sm table-striped">
										<thead>
											<tr>
												<th>Date</th>
												<th>Amount</th>
											</tr>
										</thead>
										<tbody>
											{Payments.filter(
												(payment) =>
													parseInt(payment.invoiceLinker) ===
													parseInt(invoice.linker)
											).map((payment) => (
												<tr key={payment.linker}>
													<td>
														{new Date(
															parseInt(payment.linker)
														).toLocaleDateString("en-US")}
													</td>
													<td>{payment.amount}</td>
												</tr>
											))}
										</tbody>
									</table>
								) : null}
							</td>
							<td>
								{invoice.amount -
									Payments.filter(
										(payment) =>
											parseInt(payment.invoiceLinker) ===
											parseInt(invoice.linker)
									).reduce((a, b) => +a + +b.amount, 0)}
							</td>
							<td>
								{new Date(parseInt(invoice.linker)).toLocaleDateString("en-US")}
							</td>{" "}
							{/*<td>
								{invoice.amount -
									Payments.filter(
										(payment) =>
											parseInt(payment.invoiceLinker) ===
											parseInt(invoice.linker)
									).reduce((a, b) => +a + +b.amount, 0) >
								0 ? (
									<button
										className="btn btn-sm btn-success rounded-pill"
										onClick={() => {
											setShowModal(true);
											setInvoice(invoice);
										}}
									>
										M-Pesa Pay
									</button>
								) : null}
							</td>*/}
						</tr>
					))}
				</tbody>
			</table>
			<MpesaPayModal
				invoice={Invoice}
				ShowModal={ShowModal}
				setShowModal={setShowModal}
				house={House}
			></MpesaPayModal>
		</div>
	);
};

export default Client;
