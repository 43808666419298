import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import {
	BarChartLine,
	Bell,
	Cash,
	HouseDoor,
	People,
	ReceiptCutoff,
} from "react-bootstrap-icons";
import AdminDash from "../Admin/AdminDash";
import { useNavigate } from "react-router-dom";
import { FetchLiveSubs } from "../../Network/SubApi";
import { VerifySub } from "../../Utils/VerifySub";
import Client from "../Client/Client";

const HomeDash = () => {
	//redux dispatch
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const inst = useSelector((state) => state.inst.activeInst);
	const User = useSelector((state) => state.auth.user);

	useEffect(() => {
		FetchLiveSubs(User.token, ActiveCred.instLinker, 0, dispatch);
	}, []);
	const subs = useSelector((state) => state.sub.subs);
	const Sub = VerifySub(subs, ActiveCred.instLinker);

	return Sub > 0 ? (
		<div>
			{}
			<div className="row m-3 justify-content-around">
				{" "}
				<div className="col-6 col-md-4 ">
					{parseInt(ActiveCred.admin) === 1 && inst.sub === "Kenya" ? (
						<div className="dashboard mb-2">
							<Dropdown className="no-caret" style={{ width: "100%" }}>
								<Dropdown.Toggle
									style={{
										width: "100%",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
									variant="transparent"
								>
									<div>
										<div className="d-flex justify-content-center">
											<Bell className="dash-icon" />
										</div>
										<p className="text-center text-wrap dash-text  ">
											Notifications
										</p>
									</div>
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item>
										<p
											className="btn-link"
											onClick={() => {
												window.scroll(0, 0);
												navigate(`notifications`);
											}}
										>
											General Notifications
										</p>
									</Dropdown.Item>

									<Dropdown.Item>
										<p
											className="btn-link"
											onClick={() => {
												window.scroll(0, 0);
												navigate(`bulk-sms`);
											}}
										>
											SMS Notifications
										</p>
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					) : (
						<div className="dashboard mb-2">
							<Dropdown
								className="no-caret"
								style={{ width: "100%" }}
								onClick={() => {
									window.scroll(0, 0);
									navigate(`notifications`);
								}}
							>
								<Dropdown.Toggle
									style={{
										width: "100%",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
									}}
									variant="transparent"
								>
									<div>
										<div className="d-flex justify-content-center">
											<Bell className="dash-icon" />
										</div>
										<p className="text-center text-wrap dash-text  ">
											Notifications
										</p>
									</div>
								</Dropdown.Toggle>
							</Dropdown>
						</div>
					)}
				</div>
				{parseInt(ActiveCred.admin) === 1 ? <AdminDash></AdminDash> : null}
				{parseInt(ActiveCred.admin) === 1 ||
				parseInt(ActiveCred.staff) === 1 ? (
					<>
						{" "}
						<div className="col-6 col-md-4 ">
							<div className="dashboard mb-2">
								<Dropdown
									className="no-caret"
									style={{ width: "100%" }}
									onClick={() => {
										window.scroll(0, 0);
										navigate(`houses`);
									}}
								>
									<Dropdown.Toggle
										style={{
											width: "100%",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
										variant="transparent"
									>
										<div>
											<div className="d-flex justify-content-center">
												<HouseDoor className="dash-icon" />
											</div>
											<p className="text-center text-wrap dash-text  ">
												Houses / Rooms
											</p>
										</div>
									</Dropdown.Toggle>
								</Dropdown>
							</div>
						</div>
						<div className="col-6 col-md-4 ">
							<div className="dashboard mb-2">
								<Dropdown
									className="no-caret"
									style={{ width: "100%" }}
									onClick={() => {
										window.scroll(0, 0);
										navigate(`tenants`);
									}}
								>
									<Dropdown.Toggle
										style={{
											width: "100%",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
										variant="transparent"
									>
										<div>
											<div className="d-flex justify-content-center">
												<People className="dash-icon" />
											</div>
											<p className="text-center text-wrap dash-text  ">
												Tenants
											</p>
										</div>
									</Dropdown.Toggle>
								</Dropdown>
							</div>
						</div>
						<div className="col-6 col-md-4 ">
							<div className="dashboard mb-2">
								<Dropdown
									className="no-caret"
									style={{ width: "100%" }}
									onClick={() => {
										window.scroll(0, 0);
										navigate(`invoices`);
									}}
								>
									<Dropdown.Toggle
										style={{
											width: "100%",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
										variant="transparent"
									>
										<div>
											<div className="d-flex justify-content-center">
												<ReceiptCutoff className="dash-icon" />
											</div>
											<p className="text-center text-wrap dash-text  ">
												Invoices
											</p>
										</div>
									</Dropdown.Toggle>
								</Dropdown>
							</div>
						</div>
						<div className="col-6 col-md-4 ">
							<div className="dashboard mb-2">
								<Dropdown
									className="no-caret"
									style={{ width: "100%" }}
									onClick={() => {
										window.scroll(0, 0);
										navigate(`payments`);
									}}
								>
									<Dropdown.Toggle
										style={{
											width: "100%",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
										variant="transparent"
									>
										<div>
											<div className="d-flex justify-content-center">
												<Cash className="dash-icon" />
											</div>
											<p className="text-center text-wrap dash-text  ">
												Payments
											</p>
										</div>
									</Dropdown.Toggle>
								</Dropdown>
							</div>
						</div>
						<div className="col-6 col-md-4 ">
							<div className="dashboard mb-2">
								<Dropdown
									className="no-caret"
									style={{ width: "100%" }}
									onClick={() => {
										window.scroll(0, 0);
										navigate(`monthly-report`);
									}}
								>
									<Dropdown.Toggle
										style={{
											width: "100%",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
										variant="transparent"
									>
										<div>
											<div className="d-flex justify-content-center">
												<BarChartLine className="dash-icon" />
											</div>
											<p className="text-center text-wrap dash-text  ">
												Monthly Report
											</p>
										</div>
									</Dropdown.Toggle>
								</Dropdown>
							</div>
						</div>
					</>
				) : null}
			</div>
			{parseInt(ActiveCred.tenant) === 1 ? <Client></Client> : null}
		</div>
	) : (
		<></>
	);
};

export default HomeDash;
