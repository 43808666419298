import React, { useState, useEffect } from "react";
import OwnerCrud from "./OwnerCrud";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, Trash, PencilSquare } from "react-bootstrap-icons";
import NoOwner from "./NoOwner";
import { FetchOwners } from "../../../Network/OwnerApi";
import GeneratePdf from "../../../Utils/GeneratePdf";

const Owners = () => {
  useEffect(() => {
    FetchOwners(User.token, ActiveCred.instLinker, dispatch, online);
  }, []);

  const [ShowModal, setShowModal] = useState(false);
  const [Owner, setOwner] = useState({ name: "" });
  const [Search, setSearch] = useState("");
  const [Type, setType] = useState("add");

  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Owners = useSelector((state) => state.owner.owners);

  const SearchOwner = Owners.filter(
    (owner) =>
      (owner.name?.toLowerCase().search(Search.toLowerCase()) > -1 ||
        owner.email?.toLowerCase().search(Search.toLowerCase()) > -1 ||
        owner.contact?.toLowerCase().search(Search.toLowerCase()) > -1 ||
        Search === "") &&
      parseInt(owner.deleted) === 0
  ).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  return (
    <div>
      <div id="owners">
        <p className="text-center h6 text-primary text-capitalize">
          {SearchOwner.length} Property Owners
        </p>
        <table className="table table-sm">
          <thead className="thead-dark">
            <tr>
              <th scope="column">
                <input
                  type={"text"}
                  placeholder={`Search Property Owner`}
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="rounded form-control"
                />
              </th>
              <th>Contact</th>
              <th>Email</th>
              <th>Details</th>
              <td>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    setShowModal(true);
                    setType("add");
                    setOwner({ name: "", details: "" });
                  }}
                >
                  <PlusCircle /> Add Property Owner
                </button>
              </td>
            </tr>
          </thead>
          {SearchOwner.length > 0 ? (
            <tbody>
              {SearchOwner.map((owner, index) => (
                <tr key={index} className="item">
                  <td>{owner.name}</td>
                  <td>{owner.contact}</td>
                  <td>{owner.email}</td>
                  <td>{owner.details}</td>
                  <td>
                    <button
                      className="btn btn-xs btn-outline-info mx-1"
                      onClick={() => {
                        setShowModal(true);
                        setType("edit");
                        setOwner({ ...owner });
                      }}
                    >
                      {" "}
                      <PencilSquare style={{ cursor: "pointer" }} />
                    </button>
                    <button
                      className="btn btn-xs btn-outline-danger mx-1"
                      onClick={() => {
                        setShowModal(true);
                        setType("delete");
                        setOwner({ ...owner });
                      }}
                    >
                      {" "}
                      <Trash style={{ cursor: "pointer" }} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <NoOwner></NoOwner>
          )}
        </table>
      </div>
      <GeneratePdf id={"owners"} name={"owners"}></GeneratePdf>
      <OwnerCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        owner={Owner}
        setOwner={setOwner}
        type={Type}
      ></OwnerCrud>
    </div>
  );
};

export default Owners;
