import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addLiveBill, updateLiveBill } from "../../../Network/BillApi";

const BillCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const User = useSelector((state) => state.auth.user);
  const [Loading, setLoading] = useState(false);

  //add bill
  const AddBill = async () => {
    let linker = Date.now();

    await addLiveBill(
      {
        details: props.bill.details,
        linker,
        name: props.bill.name,
        instLinker: ActiveCred.instLinker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: 0,
        trace: linker,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );

    props.setBill({
      ...props.bill,
      name: "",
      details: "",
    });
  };

  //edit bill
  const EditBill = async () => {
    let {
      credLinker,

      trace,

      deleted,
    } = props.bill;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveBill(
      { ...props.bill, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add") {
      await AddBill();
    } else {
      await EditBill();
    }
    setLoading(false);
    props.setShowModal(false);
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">{props.type} Bill</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          <div className="form-group">
            <label className="mb-2 text-capitalize">Bill Name:</label>
            <input
              className="rounded form-control"
              placeholder={`enter bill name`}
              value={props.bill.name}
              onChange={(e) =>
                props.setBill({
                  ...props.bill,
                  name: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>

          <div className="form-group">
            <label className="mb-2 text-capitalize">Bill Details:</label>
            <textarea
              className="rounded form-control"
              placeholder={`enter bill details`}
              value={props.bill.details}
              onChange={(e) =>
                props.setBill({
                  ...props.bill,
                  details: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : props.type === "delete" ? (
              <Button variant="danger" type="submit">
                Delete
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BillCrud;
