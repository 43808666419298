import React from "react";

const NoHouse = () => {
  return (
    <tbody>
      <tr>
        <th>No Houses/Rooms</th>

        <td>Houses/Rooms for various tenants</td>
      </tr>
    </tbody>
  );
};

export default NoHouse;
