import React from "react";

const NoPayment = () => {
  return (
    <tbody>
      <tr>
        <th>No Payments</th>

        <td>Various Payments made by tenants</td>
      </tr>
    </tbody>
  );
};

export default NoPayment;
