import { createSlice } from "@reduxjs/toolkit";

export const BuildingSlice = createSlice({
  name: "building",
  initialState: {
    buildings: [],
  },
  reducers: {
    fetchBuildings: (state, action) => {
      state.buildings = action.payload;
    },
    addBuilding: (state, action) => {
      state.buildings = [action.payload, ...state.buildings];
    },
    updateBuilding: (state, action) => {
      let index = state.buildings.findIndex(
        (building) =>
          parseInt(building.linker) === parseInt(action.payload.linker)
      );

      let prevBuildings = [...state.buildings];

      prevBuildings.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.buildings = prevBuildings;
    },
    deleteBuilding: (state, action) => {
      let newBuildings = state.buildings.filter(
        (building) =>
          parseInt(building.linker) !== parseInt(action.payload.linker)
      );
      state.buildings = newBuildings;
    },
  },
});

export const { fetchBuildings, addBuilding, updateBuilding, deleteBuilding } =
  BuildingSlice.actions;

export default BuildingSlice.reducer;
