import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PlainList } from "flatlist-react";
import MonthlyDisp from "./MonthlyDisp";
import GeneratePdf from "../../Utils/GeneratePdf";

const Monthly = () => {
	const Creds = useSelector((state) => state.cred.creds);
	const Buildings = useSelector((state) => state.building.buildings).filter(
		(building) => parseInt(building.deleted) === 0
	);
	const Bills = useSelector((state) => state.bill.bills).filter(
		(bill) => parseInt(bill.deleted) === 0
	);
	const AllInvoices = useSelector((state) => state.invoice.invoices);
	const AllPayments = useSelector((state) => state.payment.payments);
	const AllHouses = useSelector((state) => state.house.houses);

	const [Building, setBuilding] = useState("all");
	const [Bill, setBill] = useState("all");
	const [Bal, setBal] = useState("all");
	const [Month, setMonth] = useState("All");
	const [Year, setYear] = useState("All");
	const [Status, setStatus] = useState("all");

	const HouseTenant = (linker) =>
		Creds.find(
			(tenant) =>
				parseInt(tenant.tenant) === 1 &&
				parseInt(tenant.deleted) === 0 &&
				parseInt(tenant.houseLinker) === parseInt(linker)
		) || { firstname: "empty" };

	const HouseInvoices = (linker) =>
		AllInvoices.filter(
			(invoice) =>
				parseInt(invoice.deleted) === 0 &&
				parseInt(invoice.houseLinker) === parseInt(linker) &&
				(parseInt(invoice.month.split("-")[0]) === parseInt(Year) ||
					Year === "All") &&
				(parseInt(invoice.month.split("-")[1]) === parseInt(Month) ||
					Month === "All" ||
					Year === "All") &&
				(parseInt(invoice.billLinker) === parseInt(Bill) ||
					Bill === "all" ||
					invoice.billLinker === Bill)
		);

	const HousePayments = (linker) =>
		AllPayments.filter(
			(payment) =>
				parseInt(payment.deleted) === 0 &&
				HouseInvoices(linker).some(
					(invoice) =>
						parseInt(invoice.linker) === parseInt(payment.invoiceLinker)
				)
		);

	const Houses = AllHouses.map((house) => ({
		...house,
		tenant: HouseTenant(house.linker),
		invoices: HouseInvoices(house.linker).reduce((a, b) => +a + +b.amount, 0),
		payments: HousePayments(house.linker).reduce((a, b) => +a + +b.amount, 0),
	})).filter(
		(house) =>
			parseInt(house.deleted) === 0 &&
			(parseInt(house.buildingLinker) === parseInt(Building) ||
				Building === "all") &&
			((house.tenant.linker && parseInt(Status) === 0) ||
				(!house.tenant.linker && parseInt(Status) === 1) ||
				Status === "all") &&
			(Bal === "all" ||
				(Bal === "clear" && house.invoices - house.payments <= 0) ||
				(Bal === "bal" && house.invoices - house.payments > 0))
	);

	return (
		<div>
			<div id="summary">
				<p className="text-center h6">
					<strong>
						Summary For {Month}-{Year}
					</strong>
				</p>
				<table className="table table-sm table-responsive">
					<thead>
						<tr>
							<th>Select Month :</th>
							<th>
								<input
									type={"month"}
									onClick={(e) => e.target.showPicker()}
									className="rounded"
									placeholder="Select Month"
									onChange={(e) => {
										if (e.target.value) {
											setYear(e.target.value.split("-")[0]);
											setMonth(e.target.value.split("-")[1]);
										} else {
											setMonth("All");
											setYear("All");
										}
									}}
								/>
							</th>

							<th colSpan={2}>
								<select
									className="form-control rounded"
									value={Bill}
									onChange={(e) => setBill(e.target.value)}
								>
									<option value={"all"}>All Bills</option>
									<option value={"rent"}>Rent</option>
									<option value={"deposit"}>Deposit</option>
									{Bills.map((bill) => (
										<option key={bill.linker} value={bill.linker}>
											{bill.name}
										</option>
									))}
								</select>
							</th>
							<th colSpan={2}>
								<select
									className="form-control rounded"
									value={Bal}
									onChange={(e) => setBal(e.target.value)}
								>
									<option value={"all"}>All Balances</option>
									<option value={"bal"}>With Balance</option>
									<option value={"clear"}>Cleared</option>
								</select>
							</th>
						</tr>
						<tr>
							<th>
								<select
									className="form-control rounded"
									value={Building}
									onChange={(e) => setBuilding(e.target.value)}
								>
									<option value={"all"}>All Buildings</option>
									{Buildings.map((building) => (
										<option key={building.linker} value={building.linker}>
											{building.name}
										</option>
									))}
								</select>
							</th>
							<th>
								<select
									className="form-control rounded"
									value={Status}
									onChange={(e) => setStatus(e.target.value)}
								>
									<option value={"all"}>All Houses</option>
									<option value={"0"}>Occupied Houses</option>
									<option value={"1"}>Vacant Houses</option>
								</select>
							</th>
							<th>Invoices</th>
							<th>Payments</th>
							<th>Bal</th>
							<th></th>
						</tr>
						<tr>
							<th>House No</th>
							<th>Tenant</th>
							<th>{Houses.reduce((a, b) => +a + +b.invoices, 0)}</th>
							<th>{Houses.reduce((a, b) => +a + +b.payments, 0)}</th>
							<th>
								{Houses.reduce((a, b) => +a + +b.invoices, 0) -
									Houses.reduce((a, b) => +a + +b.payments, 0)}
							</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<PlainList
							list={Houses}
							renderOnScroll
							renderItem={(house) => (
								<MonthlyDisp house={house} key={house.linker}></MonthlyDisp>
							)}
						/>
					</tbody>
				</table>
			</div>
			<GeneratePdf
				id={"summary"}
				name={`${Month}-${Year}-Monthly-Summary`}
			></GeneratePdf>
		</div>
	);
};

export default Monthly;
