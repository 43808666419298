import React, { useState, useEffect } from "react";
import { PersonAdd } from "react-bootstrap-icons";
import TenantCrud from "./TenantCrud";
import { useDispatch, useSelector } from "react-redux";
import { FetchStaffs } from "../../Network/CredApi";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../Utils/GeneratePdf";
import TenantDisp from "./TenantDisp";
import SingleSms from "../Admin/Sms/SingleSms";
import { useNavigate } from "react-router-dom";

const Tenants = () => {
	useEffect(() => {
		FetchStaffs(ActiveCred.instLinker, User.token, dispatch, online);
	}, []);

	//redux dispatch
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const User = useSelector((state) => state.auth.user);
	const online = useSelector((state) => state.online.online);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const inst = useSelector((state) => state.inst.activeInst);

	const Buildings = useSelector((state) => state.building.buildings).filter(
		(building) => parseInt(building.deleted) === 0
	);
	const [Status, setStatus] = useState("all");
	const [House, setHouse] = useState("all");
	const [Building, setBuilding] = useState("all");
	const Houses = useSelector((state) => state.house.houses).filter(
		(house) =>
			parseInt(house.deleted) === 0 &&
			(Building === "all" ||
				parseInt(Building) === parseInt(house.buildingLinker))
	);
	const Invoices = useSelector((state) => state.invoice.invoices);
	const Payments = useSelector((state) => state.payment.payments);

	const TenantInvoices = (linker) =>
		Invoices.filter(
			(invoice) =>
				parseInt(invoice.deleted) === 0 &&
				parseInt(invoice.tenantLinker) === parseInt(linker)
		);

	const TenantPayments = (linker) =>
		Payments.filter(
			(payment) =>
				parseInt(payment.deleted) === 0 &&
				TenantInvoices(linker).some(
					(invoice) =>
						parseInt(invoice.linker) === parseInt(payment.invoiceLinker)
				)
		);

	const [Search, setSearch] = useState("");
	const [ShowModal, setShowModal] = useState(false);
	const [SmsModal, setSmsModal] = useState(false);
	const [Tenant, setTenant] = useState({
		firstname: "",
		lastname: "",
		surname: "",
		contact: "",
		email: "",
		tenant: "",
		buildingLinker: "all",
		houseLinker: "",
	});
	const [Type, setType] = useState("add");

	const Tenants = useSelector((state) => state.cred.creds)
		.map((tenant) => ({
			...tenant,
			invoices: TenantInvoices(tenant.linker).reduce(
				(a, b) => +a + +b.amount,
				0
			),
			payments: TenantPayments(tenant.linker).reduce(
				(a, b) => +a + +b.amount,
				0
			),
		}))
		.filter(
			(tenant) =>
				(parseInt(tenant.tenant) === 1 || parseInt(tenant.tenant) === 2) &&
				parseInt(tenant.deleted) === 0 &&
				(parseInt(tenant.tenant) === parseInt(Status) || Status === "all") &&
				Houses.some(
					(house) => parseInt(house.linker) === parseInt(tenant.houseLinker)
				) &&
				(parseInt(House) === parseInt(tenant.houseLinker) || House === "all") &&
				((tenant.firstname || "").toLowerCase().search(Search.toLowerCase()) >
					-1 ||
					(tenant.lastname || "").toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					(tenant.surname || "").toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					(tenant.email || "").toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					(tenant.contact || "").toLowerCase().search(Search.toLowerCase()) >
						-1 ||
					Search === "") &&
				parseInt(tenant.linker) !== parseInt(inst.linker)
		)
		.sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

	return (
		<div>
			<div className="d-flex justify-content-around mt-1">
				{" "}
				<p className="text-center h6 text-primary text-capitalize">
					{Tenants.length} Tenants
				</p>
				{inst.sub === "Kenya" ? (
					<button
						className="btn btn-sm btn-outline-primary"
						onClick={() => {
							navigate(`/${inst.name}/bulk-sms`);
						}}
					>
						Sms Tenants
					</button>
				) : null}
				<button
					className="btn btn-sm btn-outline-primary"
					onClick={() => {
						setShowModal(true);
						setType("add");
						setTenant({
							firstname: "",
							lastname: "",
							surname: "",
							tenantReg: "",
							contact: "",
							email: "",
							tenant: "",
							buildingLinker: "all",
							houseLinker: "",
						});
					}}
				>
					<PersonAdd /> Add Tenant
				</button>
			</div>

			<table className="table table-sm" id="tenants">
				<thead className="thead-dark">
					<tr>
						<th colSpan={3}>
							<input
								type={"text"}
								placeholder={`Search Tenant`}
								value={Search}
								onChange={(e) => setSearch(e.target.value)}
								className="rounded form-control"
							/>
						</th>
						<th>Status</th>
						<th>Building</th>
						<th>House</th>
						<th>Inv</th>
						<th>Paid</th>
						<th>Bal</th>
						<th> </th>
					</tr>
					<tr>
						<th>Name</th>
						<th>Email</th>
						<th>Contact</th>
						<th>
							<select
								className="form-control rounded"
								value={Status}
								onChange={(e) => setStatus(e.target.value)}
							>
								<option value={"all"}>All Status</option>
								<option value={"1"}>Current</option>
								<option value={"2"}>Exited</option>
							</select>
						</th>
						<th>
							<select
								className="form-control rounded"
								value={Building}
								onChange={(e) => setBuilding(e.target.value)}
							>
								<option value={"all"}>All Buildings</option>
								{Buildings.map((building) => (
									<option value={building.linker} key={building.linker}>
										{building.name}
									</option>
								))}
							</select>
						</th>
						<th>
							<select
								className="form-control rounded"
								value={House}
								onChange={(e) => setHouse(e.target.value)}
							>
								<option value={"all"}>All Houses</option>
								{Houses.map((house) => (
									<option value={house.linker} key={house.linker}>
										{house.name}
									</option>
								))}
							</select>
						</th>
						<th>{Tenants.reduce((a, b) => +a + +b.invoices, 0)}</th>
						<th>{Tenants.reduce((a, b) => +a + +b.payments, 0)}</th>
						<th>
							{Tenants.reduce((a, b) => +a + +b.invoices, 0) -
								Tenants.reduce((a, b) => +a + +b.payments, 0)}
						</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<PlainList
						list={Tenants}
						renderOnScroll
						renderItem={(tenant) => (
							<TenantDisp
								tenant={tenant}
								setShowModal={setShowModal}
								setTenant={setTenant}
								setType={setType}
								setSmsModal={setSmsModal}
							></TenantDisp>
						)}
					/>
				</tbody>
			</table>
			<TenantCrud
				setShowModal={setShowModal}
				ShowModal={ShowModal}
				tenant={Tenant}
				setTenant={setTenant}
				type={Type}
			></TenantCrud>
			<SingleSms
				Tenant={Tenant}
				SmsModal={SmsModal}
				setSmsModal={setSmsModal}
			></SingleSms>
			<div className="d-flex justify-content-center">
				<GeneratePdf id={"tenants"} name={`tenants`}></GeneratePdf>
			</div>
		</div>
	);
};

export default Tenants;
