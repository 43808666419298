import React from "react";
import { useSelector } from "react-redux";
import { Trash, PencilSquare, CashCoin } from "react-bootstrap-icons";
import { useNavigate, useParams } from "react-router-dom";

const InvoiceDisp = ({ invoice, setShowModal, setType, setInvoice }) => {
  const navigate = useNavigate();

  const params = useParams();

  const Creds = useSelector((state) => state.cred.creds);
  const Houses = useSelector((state) => state.house.houses).filter(
    (house) => parseInt(house.deleted) === 0
  );
  const Tenants = useSelector((state) => state.cred.creds).filter(
    (tenant) => parseInt(tenant.deleted) === 0
  );

  const Bills = useSelector((state) => state.bill.bills).filter(
    (bill) => parseInt(bill.deleted) === 0
  );
  const Buildings = useSelector((state) => state.building.buildings).filter(
    (building) => parseInt(building.deleted) === 0
  );

  const FindUser = (linker) => {
    let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

    return cred || { firstname: "unknown" };
  };

  const FindHouse = Houses.find(
    (house) => parseInt(house.linker) === parseInt(invoice.houseLinker)
  ) || { name: "unKnown" };

  const FindBuilding = Buildings.find(
    (building) =>
      parseInt(building.linker) === parseInt(FindHouse.buildingLinker)
  ) || { name: "unKnown" };

  const FindTenant = Tenants.find(
    (tenant) => parseInt(tenant.linker) === parseInt(invoice.tenantLinker)
  ) || { firstname: "unknown" };

  const FindBill = Bills.find(
    (bill) => parseInt(bill.linker) === parseInt(invoice.billLinker)
  ) || { name: "unKnown" };

  return (
    <tr className="item">
      {!params.houseLinker ? (
        <>
          <td>{FindBuilding.name}</td>
          <td>{FindHouse.name}</td>
        </>
      ) : null}

      <td>
        {FindTenant.firstname} {FindTenant.lastname} {FindTenant.surname}
      </td>
      <td>
        {invoice.billLinker === "rent"
          ? "rent"
          : invoice.billLinker === "deposit"
          ? "deposit"
          : FindBill.name}
      </td>
      <td>{invoice.month}</td>
      <td>{invoice.amount}</td>

      <td>{invoice.payments}</td>
      <td>{invoice.amount - invoice.payments}</td>

      <td>{new Date(parseInt(invoice.linker)).toLocaleDateString("en-US")}</td>
      <td>{FindUser(invoice.credLinker).email}</td>
      <td>
        <PencilSquare
          onClick={() => {
            setShowModal(true);
            setType("edit");
            setInvoice({ ...invoice });
          }}
          style={{ cursor: "pointer" }}
          className="text-info mx-2"
        />

        <Trash
          onClick={() => {
            setShowModal(true);
            setType("delete");
            setInvoice({ ...invoice });
          }}
          style={{ cursor: "pointer" }}
          className="text-danger mx-2"
        />

        <CashCoin
          onClick={() => {
            navigate(`invoice-payments/${invoice.linker}`);
          }}
          style={{ cursor: "pointer" }}
          className="text-primary mx-2"
        />
      </td>
    </tr>
  );
};

export default InvoiceDisp;
