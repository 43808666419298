import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SendMessages } from "../../../Network/MessageApi";
import PurchaseModal from "./PurchaseModal";

const BulkSms = () => {
	//redux dispatch
	const dispatch = useDispatch();

	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const inst = useSelector((state) => state.inst.activeInst);
	const messages = useSelector((state) => state.message.messages)
		.filter((message) => message.id)
		.sort((a, b) => a.id - b.id);
	const Buildings = useSelector((state) => state.building.buildings);

	const [Building, setBuilding] = useState("all");
	const [Message, setMessage] = useState("");
	const [Loading, setLoading] = useState(false);
	const [ShowModal, setShowModal] = useState(false);

	const Houses = useSelector((state) => state.house.houses).filter(
		(house) =>
			parseInt(house.deleted) === 0 &&
			(Building === "all" ||
				parseInt(Building) === parseInt(house.buildingLinker))
	);

	const Tenants = useSelector((state) => state.cred.creds).filter(
		(tenant) =>
			parseInt(tenant.tenant) === 1 &&
			parseInt(tenant.deleted) === 0 &&
			Houses.some(
				(house) => parseInt(house.linker) === parseInt(tenant.houseLinker)
			)
	);

	const HandleSend = (e) => {
		e.preventDefault();
		//ensure tenat has contact then create message object
		let Messages = Tenants.filter(
			(tenant) => !/^$/.test(tenant.contact) && tenant.contact
		).map((tenant) => ({
			mobile: tenant.contact.toString().replace(/^\+/, "").replace(/^0/, "254"),
			message: `${inst.name
				.split(" ")
				.map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
				.join(" ")}: ${Message}`,
		}));

		// group messages in chunks of 20
		let Chunks = Messages.reduce(
			(acc, _, i) => (i % 20 ? acc : [...acc, Messages.slice(i, i + 20)]),
			[]
		);

		//maps chunks and send each chunk
		Chunks.map((chunk) =>
			SendMessages(
				User.token,
				ActiveCred.instLinker,
				chunk,
				dispatch,
				setLoading
			)
		);
	};

	return (
		<>
			<div className="row justify-content-around">
				<div className="col-md-12 col-lg-12 d-flex justify-content-around align-items-center">
					<p className="h6">
						You Have{" "}
						{messages.length > 0 ? messages[messages.length - 1].cf : 0}{" "}
						Messages
					</p>
					<button
						className="btn btn-outline-success rounded"
						onClick={() => setShowModal(true)}
					>
						Purchase Messages
					</button>
				</div>
			</div>{" "}
			<div className="row justify-content-around">
				<div className="col-md-12 col-lg-8">
					<div className="card">
						<div className="card-header">
							<p className="card-title">
								Message {Tenants.length} Tenants :{" "}
								<e className="text-danger">
									{Tenants.length >
									(messages.length > 0 ? messages[messages.length - 1].cf : 0)
										? `{No Enough Messages; Purchase}`
										: null}
								</e>
							</p>
						</div>
						<form onSubmit={HandleSend}>
							<div className="card-body">
								{Buildings.length > 1 ? (
									<div className="form-group">
										<label>Select Building/Hostel :</label>
										<select
											onChange={(e) => setBuilding(e.target.value)}
											value={Building}
											className="form-control rounded"
										>
											<option value={"all"}>All Buildings/Hostels</option>
											{Buildings.map((building) => (
												<option key={building.linker} value={building.linker}>
													{building.name}
												</option>
											))}
										</select>
										<hr />
									</div>
								) : null}
								<div className="form-group">
									<label>Message:</label>
									<textarea
										required
										placeholder="Type message..."
										value={Message}
										onChange={(e) => setMessage(e.target.value)}
										className="form-control rounded"
									/>
								</div>
							</div>
							<div className="card-footer d-flex justify-content-around">
								{Loading ? (
									<span className="text-success spinner-border"></span>
								) : Tenants.length >
								  (messages.length > 0
										? messages[messages.length - 1].cf
										: 0) ? (
									`{No Enough Messages; Purchase Above}`
								) : (
									<button type="submit" className="btn btn-success">
										Send Sms
									</button>
								)}
							</div>
						</form>
					</div>
				</div>
			</div>
			<PurchaseModal
				ShowModal={ShowModal}
				setShowModal={setShowModal}
			></PurchaseModal>
		</>
	);
};

export default BulkSms;
