import React, { useState, useEffect } from "react";
import BillCrud from "./BillCrud";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, Trash, PencilSquare } from "react-bootstrap-icons";
import NoBill from "./NoBills";
import { FetchBills } from "../../../Network/BillApi";
import GeneratePdf from "../../../Utils/GeneratePdf";

const Bills = () => {
  useEffect(() => {
    FetchBills(User.token, ActiveCred.instLinker, dispatch, online);
  }, []);

  const [ShowModal, setShowModal] = useState(false);
  const [Bill, setBill] = useState({ name: "" });
  const [Owner, setOwner] = useState("all");
  const [Search, setSearch] = useState("");
  const [Type, setType] = useState("add");

  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Bills = useSelector((state) => state.bill.bills);

  const SearchBill = Bills.filter(
    (bill) =>
      (bill.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
        Search === "") &&
      parseInt(bill.deleted) === 0
  ).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  return (
    <div>
      <div id="bills">
        <p className="text-center h6 text-primary text-capitalize">
          {SearchBill.length} Bills
        </p>
        <table className="table table-sm">
          <thead className="thead-dark">
            <tr>
              <th scope="column" colSpan={2}>
                <input
                  type={"text"}
                  placeholder={`Search Bill`}
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="rounded form-control"
                />
              </th>

              <td>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    setShowModal(true);
                    setType("add");
                    setBill({ name: "", details: "", ownerLinker: "" });
                  }}
                >
                  <PlusCircle /> Add Bill
                </button>
              </td>
            </tr>
          </thead>
          {SearchBill.length > 0 ? (
            <tbody>
              {SearchBill.map((bill, index) => (
                <tr key={index} className="item">
                  <td>{bill.name}</td>
                  <td>{bill.details}</td>

                  <td>
                    <button
                      className="btn btn-xs btn-outline-info mx-1"
                      onClick={() => {
                        setShowModal(true);
                        setType("edit");
                        setBill({ ...bill });
                      }}
                    >
                      {" "}
                      <PencilSquare style={{ cursor: "pointer" }} />
                    </button>
                    <button
                      className="btn btn-xs btn-outline-danger mx-1"
                      onClick={() => {
                        setShowModal(true);
                        setType("delete");
                        setBill({ ...bill });
                      }}
                    >
                      {" "}
                      <Trash style={{ cursor: "pointer" }} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <NoBill></NoBill>
          )}
        </table>
      </div>
      <GeneratePdf id={"bills"} name={"bills"}></GeneratePdf>
      <BillCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        bill={Bill}
        setBill={setBill}
        type={Type}
      ></BillCrud>
    </div>
  );
};

export default Bills;
