import React from "react";
import { useParams } from "react-router-dom";

const NoBuilding = () => {
  const params = useParams();
  return (
    <tbody>
      <tr>
        <td>No Buildings</td>

        <td>These are various properties that you manage</td>
      </tr>
    </tbody>
  );
};

export default NoBuilding;
