import React, { Component, lazy, Suspense } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./css/App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ResetPassword from "./Pages/Auth/ResetPassword";
import Login from "./Pages/Home/LogIn";
import InstPrivacy from "./Pages/Home/InstPrivacy";
import InstTerms from "./Pages/Home/InstTerms";
import WebPay from "./Pages/SubPay/WebPay";
import Inst from "./Pages/Inst/Inst";
import Modes from "./Pages/Admin/Modes/Modes";
import ExpenseCats from "./Pages/Finance/Expenses/Categories/ExpenseCats";
import Expenses from "./Pages/Finance/Expenses/Expenses/Expenses";
import Staffs from "./Pages/Admin/Staffs/Staffs";
import BuildingHouses from "./Pages/Houses/BuildingHouses";
import HouseInvoices from "./Pages/Invoices/HouseInvoices";
import Monthly from "./Pages/Monthly/Monthly";
import Payments from "./Pages/Payments/Payments";
import InvoicePayments from "./Pages/Invoices/InvoicePayments";
import Invoices from "./Pages/Invoices/Invoices";
import Bills from "./Pages/Admin/Bills/Bills";
import Buildings from "./Pages/Admin/Buildings/Buildings";
import Tenants from "./Pages/Tenants/Tenants";
import Houses from "./Pages/Houses/Houses";
import Owners from "./Pages/Admin/Owners/Owners";
import HomeDash from "./Pages/Dashboard/HomeDash";
import Profile from "./Pages/Auth/Profile";
import EditInst from "./Pages/Inst/EditInst";
import SoftwarePay2 from "./Pages/SubPay2/SoftwarePay2";
import WebPa2y from "./Pages/SubPay2/WebPay2";
import PayollCats from "./Pages/Admin/Payroll/Categories/Cats";
import PayrollItems from "./Pages/Admin/Staffs/StaffPayroll/PayrollItems";
import PayrollSummary from "./Pages/Admin/Payroll/PayrollSummary";
import MyPaySlips from "./Pages/Auth/MyPaySlips";
import Notifications from "./Pages/Notifications/Notifications";
import MpesaOnline from "./Pages/Admin/OnlinePay/Mpesa/SetUp/MpesaOnline";
import MpesaPayments from "./Pages/Admin/OnlinePay/Mpesa/Payments/MpesaPayments";
import BulkSms from "./Pages/Admin/Sms/BulkSms";
import IncomeStatement from "./Pages/Finance/Statements/IncomeStatement";

const Terms = lazy(() => import("./Pages/Home/Terms"));
const Privacy = lazy(() => import("./Pages/Home/Privacy"));
const SoftwarePay = lazy(() => import("./Pages/SubPay/SoftwarePay"));
const Dashboard = lazy(() => import("./Pages/Dashboard/Dashboard"));
const Home = lazy(() => import("./Pages/Home/Home"));

class App extends Component {
	render() {
		return (
			<div>
				<BrowserRouter basename="/">
					<Suspense
						fallback={
							<div className="d-flex justify-content-center align-items-center">
								<span className="spinner-border text-primary"></span>
							</div>
						}
					>
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/reset/:reset/:logger" element={<ResetPassword />} />
							<Route path="terms" element={<Terms />}></Route>
							<Route path="privacy" element={<Privacy />}></Route>
							<Route path="login" element={<Login />}></Route>
							<Route path="inst" element={<Inst />} />
							<Route path="activate" element={<SoftwarePay />} />{" "}
							{
								//Route to for version two of software payments
								<Route path="activate-software" element={<SoftwarePay2 />} />
							}
							<Route path=":instName" element={<Dashboard />}>
								<Route path="/:instName" element={<HomeDash />}></Route>{" "}
								<Route
									path="activate/:instName/:clientId"
									element={<WebPay />}
								></Route>
								{
									//Route for version two of web payments
									<Route
										path="activate-web/:instName/:clientId"
										element={<WebPa2y />}
									></Route>
								}
								<Route path="edit" element={<EditInst />}></Route>
								<Route path="terms" element={<InstTerms />}></Route>
								<Route path="privacy" element={<InstPrivacy />}></Route>
								<Route path="profile" element={<Profile />}></Route>
								<Route path="profile" element={<Profile />}></Route>
								<Route path="staffs" element={<Staffs />}></Route>{" "}
								<Route path="payslips" element={<MyPaySlips />}></Route>
								<Route path="staffs" element={<Staffs />}></Route>
								<Route path="payroll-items" element={<PayollCats />}></Route>
								<Route
									path="staffs/payroll-items/:staffName/:staffNo/:staffLinker"
									element={<PayrollItems />}
								></Route>
								<Route
									path="payroll-summary"
									element={<PayrollSummary />}
								></Route>
								<Route
									path="income-statement"
									element={<IncomeStatement />}
								></Route>
								<Route path="notifications" element={<Notifications />}></Route>
								<Route path="bulk-sms" element={<BulkSms />}></Route>
								<Route path="modes" element={<Modes />}></Route>{" "}
								<Route
									path="modes/mpesa-setup"
									element={<MpesaOnline />}
								></Route>
								<Route path="expenses" element={<Expenses />}></Route>
								<Route path="owners" element={<Owners />}></Route>
								<Route path="buildings" element={<Buildings />}></Route>
								<Route path="houses" element={<Houses />}></Route>
								<Route path="tenants" element={<Tenants />}></Route>
								<Route path="bills" element={<Bills />}></Route>
								<Route path="invoices" element={<Invoices />}></Route>
								<Route path="payments" element={<Payments />}></Route>
								<Route
									path="mpesa-payments"
									element={<MpesaPayments />}
								></Route>
								<Route path="monthly-report" element={<Monthly />}></Route>
								<Route
									path="invoices/invoice-payments/:invoiceLinker"
									element={<InvoicePayments />}
								></Route>
								<Route
									path="monthly-report/house-invoices/:houseLinker"
									element={<HouseInvoices />}
								></Route>
								<Route
									path="houses/house-invoices/:houseLinker"
									element={<HouseInvoices />}
								></Route>
								<Route
									path="tenants/house-invoices/:houseLinker"
									element={<HouseInvoices />}
								></Route>
								<Route
									path="monthly-report/house-invoices/:houseLinker/invoice-payments/:invoiceLinker"
									element={<InvoicePayments />}
								></Route>
								<Route
									path="tenants/house-invoices/:houseLinker/invoice-payments/:invoiceLinker"
									element={<InvoicePayments />}
								></Route>
								<Route
									path="houses/house-invoices/:houseLinker/invoice-payments/:invoiceLinker"
									element={<InvoicePayments />}
								></Route>
								<Route
									path="buildings/building-houses/:buildingLinker"
									element={<BuildingHouses />}
								></Route>
								<Route
									path="buildings/building-houses/:buildingLinker/house-invoices/:houseLinker"
									element={<HouseInvoices />}
								></Route>
								<Route
									path="buildings/building-houses/:buildingLinker/house-invoices/:houseLinker/invoice-payments/:invoiceLinker"
									element={<InvoicePayments />}
								></Route>
							</Route>
							{/*<Route path="*" element={<NotFound />} />*/}
						</Routes>
					</Suspense>
				</BrowserRouter>
			</div>
		);
	}
}

export default App;
