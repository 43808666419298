import { createSlice } from "@reduxjs/toolkit";

export const HouseSlice = createSlice({
  name: "house",
  initialState: {
    houses: [],
  },
  reducers: {
    fetchHouses: (state, action) => {
      state.houses = action.payload;
    },
    addHouse: (state, action) => {
      state.houses = [action.payload, ...state.houses];
    },
    updateHouse: (state, action) => {
      let index = state.houses.findIndex(
        (house) => parseInt(house.linker) === parseInt(action.payload.linker)
      );

      let prevHouses = [...state.houses];

      prevHouses.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.houses = prevHouses;
    },
    deleteHouse: (state, action) => {
      let newHouses = state.houses.filter(
        (house) => parseInt(house.linker) !== parseInt(action.payload.linker)
      );
      state.houses = newHouses;
    },
  },
});

export const { fetchHouses, addHouse, updateHouse, deleteHouse } =
  HouseSlice.actions;

export default HouseSlice.reducer;
