import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { ApiUrl } from "../../../Network/Urls";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { SocketJoinRoom, socket } from "../../../Utils/SockectConnect";

const MpesaPay = (props) => {
	useEffect(() => {
		ConnectRoom();
		ReceiveMessages();
	}, []);

	const inst = useSelector((state) => state.inst.activeInst);
	const navigate = useNavigate();

	const [Loading, setLoading] = useState(false);
	const [Phone, setPhone] = useState("");
	const [Code, setCode] = useState({});
	const [ShowModal, setShowModal] = useState(false);

	const ConnectRoom = () => {
		SocketJoinRoom({
			clientId: `${props.softwareId}`.split("").reverse().join(""),
		});
	};

	const ReceiveMessages = () => {
		setLoading(false);
		setShowModal(false);

		socket.on("message", (message) => {
			if (props.from === "software") {
				setCode(message.dataValues);
				swal({
					title: "Activation Successfull",
					text: `Code is ${message.dataValues.code}`,
					icon: "success",
					timer: 3000,
				});
			} else {
				//navigate(`/${inst.name}`);
			}
		});
	};

	const HandleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		fetch(`${ApiUrl}/pay/online/${props.type}/${props.softwareId}`, {
			method: "POST",
			headers: {
				"content-type": "application/json",
			},
			body: JSON.stringify({
				phone: Phone.toString().replace(/^\+/, "").replace(/^0/, "254"),

				amount: props.amount,
			}),
		})
			.then((res) => res.json())
			.then((res) => {
				setLoading(false);
				if (res.status === 200) {
					swal({
						title: "Transaction Initiated",
						text: `Check your phone to complete the transaction to DAZILA SYSTEMS account ${props.softwareId}`,
						icon: "success",
					});
				} else {
					swal({
						title: "Failed to connect",
						text: "Confirm your phone number is correct and try again",
						icon: "warning",
					});
				}
			})
			.catch(() => {
				setLoading(false);
				swal({
					title: "Failed to connect",
					icon: "warning",
					text: "Check your network and try again",
				});
			});
	};

	return (
		<div>
			<div className="d-flex justify-content-center">
				{Code.code && props.from === "software" ? (
					<em>
						Activation Code is <strong>{Code.code}</strong>
					</em>
				) : (
					<button
						onClick={() => {
							setShowModal(true);
							setPhone("");
							setLoading(false);
						}}
						className="btn btn-sm btn-success rounded-pill"
					>
						<strong>M-pesa Pay</strong>
					</button>
				)}
			</div>
			<Modal
				show={ShowModal}
				onHide={() => {
					setShowModal(false);
					setLoading(false);
				}}
				backdrop="static"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<p className="text-capitalize text-center">
							Pay With M-Pesa: amount {props.amount}
							<br />
							<small>
								<em>{"{Ensure The Phone Is On And Unlocked}"}</em>
							</small>
						</p>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={HandleSubmit}>
						<div className="form-group">
							<label className="mb-2 text-capitalize">
								Phone Number To Pay From:
							</label>
							<input
								type="number"
								className="rounded form-control"
								placeholder={`enter phone number`}
								value={Phone}
								onChange={(e) => setPhone(e.target.value)}
								required
							/>
							<hr />
						</div>

						<div className="d-flex justify-content-around mb-2">
							{Loading ? (
								<span className="spinner-border text-primary"></span>
							) : (
								<>
									{" "}
									<Button variant="primary" type="submit">
										Send
									</Button>
									<Button
										variant="secondary"
										type="button"
										onClick={() => {
											setShowModal(false);
											setLoading(false);
										}}
									>
										Cancel
									</Button>{" "}
								</>
							)}
						</div>
					</Form>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default MpesaPay;
