import React, { useState, useEffect } from "react";
import PaymentCrud from "./PaymentCrud";
import { useDispatch, useSelector } from "react-redux";
import NoPayment from "./NoPayment";
import { fetchCreds } from "../../reducers/CredSlice";
import PaymentDisp from "./PaymentDisp";
import { FetchPayments } from "../../Network/PaymentApi";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../Utils/GeneratePdf";

const Payments = () => {
  useEffect(() => {
    FetchPayments(User.token, ActiveCred.instLinker, dispatch, online);
  }, []);

  const [ShowModal, setShowModal] = useState(false);
  const [Payment, setPayment] = useState({ name: "" });
  const [Type, setType] = useState("add");
  const [By, setBy] = useState("all");
  const [Mode, setMode] = useState("all");
  const [Day, setDay] = useState("all");
  const [Month, setMonth] = useState("all");
  const [Year, setYear] = useState("all");
  const [Building, setBuilding] = useState("all");
  const [House, setHouse] = useState("all");
  const [Bill, setBill] = useState("all");
  const [Tenant, setTenant] = useState("all");
  const [Yr, setYr] = useState("all");
  const [Mth, setMth] = useState("all");
  const [ActiveTenant, setActiveTenant] = useState({});

  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Payments = useSelector((state) => state.payment.payments);

  const Creds = useSelector((state) => state.cred.creds);
  const Houses = useSelector((state) => state.house.houses).filter(
    (house) =>
      parseInt(house.deleted) === 0 &&
      (Building === "all" ||
        parseInt(Building) === parseInt(house.buildingLinker))
  );
  const Tenants = useSelector((state) => state.cred.creds).filter(
    (tenant) =>
      parseInt(tenant.deleted) === 0 &&
      (parseInt(tenant.tenant) === 1 || parseInt(tenant.tenant) === 2) &&
      (House === "all" || parseInt(House) === parseInt(tenant.houseLinker)) &&
      Houses.some(
        (house) => parseInt(house.linker) === parseInt(tenant.houseLinker)
      )
  );
  const Invoices = useSelector((state) => state.invoice.invoices);

  const FilteredInvoices = Invoices.filter(
    (invoice) =>
      parseInt(invoice.deleted) === 0 &&
      (parseInt(invoice.tenantLinker) === parseInt(Tenant) ||
        Tenant === "all") &&
      (parseInt(invoice.houseLinker) === parseInt(House) || House === "all") &&
      Houses.some(
        (house) => parseInt(house.linker) === parseInt(invoice.houseLinker)
      ) &&
      (parseInt(invoice.month.split("-")[0]) === parseInt(Yr) ||
        Yr === "all") &&
      (parseInt(invoice.month.split("-")[1]) === parseInt(Mth) ||
        Mth === "all" ||
        Yr === "all")
  );

  const Modes = useSelector((state) => state.mode.modes).filter(
    (mode) => parseInt(mode.deleted) === 0
  );

  const Bills = useSelector((state) => state.bill.bills).filter(
    (bill) => parseInt(bill.deleted) === 0
  );
  const Buildings = useSelector((state) => state.building.buildings).filter(
    (building) => parseInt(building.deleted) === 0
  );

  const FindUser = (linker) => {
    let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

    return cred || { email: "unknown" };
  };

  const SearchPayment = Payments.filter(
    (payment) =>
      parseInt(payment.deleted) === 0 &&
      (parseInt(payment.name) === parseInt(Bill) ||
        Bill === "all" ||
        payment.name === Bill) &&
      (parseInt(payment.mode) === parseInt(Mode) || Mode === "all") &&
      FilteredInvoices.some(
        (invoice) =>
          parseInt(invoice.linker) === parseInt(payment.invoiceLinker)
      ) &&
      (parseInt(
        new Date(parseInt(payment.linker)).toLocaleDateString().split("/")[2]
      ) === parseInt(Year) ||
        Year === "all") &&
      (parseInt(
        new Date(parseInt(payment.linker)).toLocaleDateString().split("/")[0]
      ) === parseInt(Month) ||
        Year === "all" ||
        Month === "all") &&
      (parseInt(
        new Date(parseInt(payment.linker)).toLocaleDateString().split("/")[1]
      ) === parseInt(Day) ||
        Year === "all" ||
        Month === "all" ||
        Day == "all") &&
      (parseInt(payment.credLinker) === parseInt(By) || By === "all")
  ).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  return (
    <div>
      <div id="payments">
        <div className="d-flex justify-content-around mt-2">
          <p className="text-center h6 text-primary text-capitalize">
            {SearchPayment.length} Payments
          </p>{" "}
        </div>

        <table className="table table-sm">
          <thead className="thead-dark">
            <tr>
              <th scope="column">Building</th>
              <th scope="colum">House</th>
              <th scope="colum">Tenant</th>
              <th scope="colum">Type</th>
              <th scope="colum">Month</th>
              <th scope="colum">Mode</th>
              <th scope="colum">Details</th>
              <th scope="column">Amount</th>
              <th scope="column">Date</th>
              <th scope="column">By</th>
            </tr>
            <tr>
              <th scope="column">
                {" "}
                <select
                  className="form-control rounded"
                  value={Building}
                  onChange={(e) => setBuilding(e.target.value)}
                >
                  <option value={"all"}>All Buildings</option>
                  {Buildings.map((building, index) => (
                    <option key={index} value={building.linker}>
                      {building.name}
                    </option>
                  ))}
                </select>
              </th>
              <th scope="column">
                <select
                  className="form-control rounded"
                  value={House}
                  onChange={(e) => setHouse(e.target.value)}
                >
                  <option value={"all"}>All Houses</option>
                  {Houses.map((house, index) => (
                    <option key={index} value={house.linker}>
                      {house.name}
                    </option>
                  ))}
                </select>
              </th>
              <th scope="column">
                <select
                  className="form-control rounded"
                  value={Tenant}
                  onChange={(e) => setTenant(e.target.value)}
                >
                  <option value={"all"}>All Tenants</option>
                  {Tenants.map((tenant, index) => (
                    <option key={index} value={tenant.linker}>
                      {tenant.firstname} {tenant.lastname} {tenant.surname}
                    </option>
                  ))}
                </select>
              </th>
              <th scope="column">
                <select
                  className="form-control rounded"
                  value={Bill}
                  onChange={(e) => setBill(e.target.value)}
                >
                  <option value={"all"}>All Types</option>
                  <option value={"rent"}>Rent</option>
                  <option value={"deposit"}>Deposit</option>
                  {Bills.map((bill, index) => (
                    <option key={index} value={bill.linker}>
                      {bill.name}
                    </option>
                  ))}
                </select>
              </th>
              <th>
                <div className="d-flex">
                  {" "}
                  <select
                    className="form-control rounded"
                    value={Yr}
                    onChange={(e) => setYr(e.target.value)}
                  >
                    <option value={"all"}>All Yrs</option>
                    {[
                      ...new Set(
                        Invoices.map((invoice) => invoice.month.split("-")[0])
                      ),
                    ].map((yr) => (
                      <option key={yr} value={yr}>
                        {yr}
                      </option>
                    ))}
                  </select>
                  {Yr !== "all" ? (
                    <select
                      className="form-control rounded"
                      value={Mth}
                      onChange={(e) => setMth(e.target.value)}
                    >
                      <option value={"all"}>All Mths</option>
                      {[
                        ...new Set(
                          Invoices.map((invoice) => invoice.month.split("-")[1])
                        ),
                      ].map((mth) => (
                        <option key={mth} value={mth}>
                          {mth}
                        </option>
                      ))}
                    </select>
                  ) : null}
                </div>
              </th>
              <th scope="column">
                <select
                  className="form-control rounded"
                  value={Mode}
                  onChange={(e) => setMode(e.target.value)}
                >
                  <option value={"all"}>All Modes</option>
                  {Modes.map((mode, index) => (
                    <option key={index} value={mode.linker}>
                      {mode.name}
                    </option>
                  ))}
                </select>
              </th>
              <th></th>

              <th>{SearchPayment.reduce((a, b) => +a + +b.amount, 0)}</th>
              <th>
                <div className="d-flex">
                  <select
                    className="form-control rounded"
                    value={Year}
                    onChange={(e) => setYear(e.target.value)}
                  >
                    <option value={"all"}>All Yrs</option>
                    {[
                      ...new Set(
                        Payments.map(
                          (payment) =>
                            new Date(parseInt(payment.linker))
                              .toLocaleDateString()
                              .split("/")[2]
                        )
                      ),
                    ].map((yr) => (
                      <option key={yr} value={yr}>
                        {yr}
                      </option>
                    ))}
                  </select>
                  {Year !== "all" ? (
                    <select
                      className="form-control rounded"
                      value={Month}
                      onChange={(e) => setMonth(e.target.value)}
                    >
                      <option value={"all"}>All Mths</option>
                      {[
                        ...new Set(
                          Payments.map(
                            (payment) =>
                              new Date(parseInt(payment.linker))
                                .toLocaleDateString()
                                .split("/")[0]
                          )
                        ),
                      ].map((mth) => (
                        <option key={mth} value={mth}>
                          {mth}
                        </option>
                      ))}
                    </select>
                  ) : null}
                  {Month !== "all" && Year !== "all" ? (
                    <select
                      className="form-control rounded"
                      value={Day}
                      onChange={(e) => setDay(e.target.value)}
                    >
                      <option value={"all"}>All Dates</option>
                      {[
                        ...new Set(
                          Payments.map(
                            (payment) =>
                              new Date(parseInt(payment.linker))
                                .toLocaleDateString()
                                .split("/")[1]
                          )
                        ),
                      ].map((day) => (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                  ) : null}
                </div>
              </th>
              <th>
                <select
                  className="form-control rounded"
                  value={By}
                  onChange={(e) => setBy(e.target.value)}
                >
                  <option value={"all"}>All Staffs</option>
                  <PlainList
                    list={[
                      ...new Set(Payments.map((payment) => payment.credLinker)),
                    ]}
                    renderOnScroll
                    renderItem={(staff) => (
                      <option value={staff} key={staff}>
                        {FindUser(staff).email}
                      </option>
                    )}
                  />
                </select>
              </th>
            </tr>
          </thead>
          {SearchPayment.length > 0 ? (
            <tbody>
              <PlainList
                list={SearchPayment}
                renderOnScroll
                renderItem={(payment) => (
                  <PaymentDisp
                    setShowModal={setShowModal}
                    ShowModal={ShowModal}
                    payment={payment}
                    setPayment={setPayment}
                    type={Type}
                    setType={setType}
                    setActiveTenant={setActiveTenant}
                    invoice={
                      Invoices.find(
                        (invoice) =>
                          parseInt(invoice.linker) ===
                          parseInt(payment.invoiceLinker)
                      ) || {}
                    }
                    key={payment.linker}
                  ></PaymentDisp>
                )}
              />
            </tbody>
          ) : (
            <NoPayment></NoPayment>
          )}
        </table>
      </div>
      <GeneratePdf name={"payments"} id={"payments"}></GeneratePdf>
      <PaymentCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        payment={Payment}
        setPayment={setPayment}
        type={Type}
        tenant={ActiveTenant}
      ></PaymentCrud>
    </div>
  );
};

export default Payments;
