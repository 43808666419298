import React from "react";

const NoInvoice = () => {
  return (
    <tbody>
      <tr>
        <th>No Invoices</th>

        <td>
          Invoices are various payments tenants are expected to pay ie rent,
          bills
        </td>
      </tr>
    </tbody>
  );
};

export default NoInvoice;
