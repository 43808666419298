import React, { useState, useEffect } from "react";
import HouseCrud from "./HouseCrud";
import { useDispatch, useSelector } from "react-redux";

import NoHouse from "./NoHouse";
import { fetchCreds } from "../../reducers/CredSlice";
import HouseDisp from "./HouseDisp";
import { FetchHouses } from "../../Network/HouseApi";
import { PlainList } from "flatlist-react";
import TenantCrud from "../Tenants/TenantCrud";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowBarLeft } from "react-bootstrap-icons";
import GeneratePdf from "../../Utils/GeneratePdf";

const BuildingHouses = () => {
  useEffect(() => {
    FetchHouses(User.token, ActiveCred.instLinker, dispatch, online);
  }, []);

  const params = useParams();
  const Navigate = useNavigate();

  const [ShowModal, setShowModal] = useState(false);
  const [TenantModal, setTenantModal] = useState(false);
  const [House, setHouse] = useState({ name: "" });
  const [Search, setSearch] = useState("");
  const [Type, setType] = useState("add");
  const [Tenancy, setTenancy] = useState("all");

  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Houses = useSelector((state) => state.house.houses).filter(
    (house) => parseInt(house.deleted) === 0
  );
  const Creds = useSelector((state) => state.cred.creds);
  const Buildings = useSelector((state) => state.building.buildings).filter(
    (building) => parseInt(building.deleted) === 0
  );
  const Building = Buildings.find(
    (building) => parseInt(building.linker) === parseInt(params.buildingLinker)
  );
  const AllPayments = useSelector((state) => state.payment.payments);
  const AllInvoices = useSelector((state) => state.invoice.invoices);
  const [Tenant, setTenant] = useState({
    firstname: "",
    lastname: "",
    surname: "",
    contact: "",
    email: "",
    tenant: "",
    buildingLinker: "all",
    houseLinker: "",
  });

  const HouseTenant = (linker) =>
    Creds.find(
      (tenant) =>
        parseInt(tenant.tenant) === 1 &&
        parseInt(tenant.deleted) === 0 &&
        parseInt(tenant.houseLinker) === parseInt(linker)
    ) || { firstname: "empty" };

  const HouseInvoices = (linker) =>
    AllInvoices.filter(
      (invoice) =>
        parseInt(invoice.deleted) === 0 &&
        parseInt(invoice.houseLinker) === parseInt(linker)
    );

  const HousePayments = (linker) =>
    AllPayments.filter(
      (payment) =>
        parseInt(payment.deleted) === 0 &&
        HouseInvoices(linker).some(
          (invoice) =>
            parseInt(invoice.linker) === parseInt(payment.invoiceLinker)
        )
    );

  const SearchHouse = Houses.map((house) => ({
    ...house,
    tenant: HouseTenant(house.linker),
    invoices: HouseInvoices(house.linker).reduce((a, b) => +a + +b.amount, 0),
    payments: HousePayments(house.linker).reduce((a, b) => +a + +b.amount, 0),
  }))
    .filter(
      (house) =>
        (house.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
          house.details.toLowerCase().search(Search.toLowerCase()) > -1 ||
          Search === "") &&
        ((house.tenant.linker && parseInt(Tenancy) === 0) ||
          (!house.tenant.linker && parseInt(Tenancy) === 1) ||
          Tenancy === "all") &&
        parseInt(house.deleted) === 0 &&
        parseInt(house.buildingLinker) === parseInt(params.buildingLinker)
    )
    .sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  return (
    <div>
      <div className="d-flex justify-content-around mt-2">
        <div>
          <button
            className="btn btn-sm btn-outline-secondary"
            onClick={() => Navigate(-1)}
          >
            <ArrowBarLeft></ArrowBarLeft> Go Back
          </button>
        </div>
        <p className="text-center h6 text-primary text-capitalize">
          {SearchHouse.length} Houses {Building?.name}
        </p>{" "}
        <button
          className="btn btn-sm btn-outline-secondary"
          onClick={() => {
            setType("add");
            setShowModal(true);
            setHouse({
              linker: Date.now(),
              trace: Date.now(),
              name: "",
              details: "",
              rent: "",
              deposit: "",
              buildingLinker: params.buildingLinker,
              building: Building,
              deleted: "0",
            });
          }}
        >
          Add House
        </button>
      </div>

      <table className="table table-sm" id="building-houses">
        <thead className="thead-dark">
          <tr>
            <th scope="column" colSpan={2}>
              <input
                type={"text"}
                placeholder={`Search House`}
                value={Search}
                onChange={(e) => setSearch(e.target.value)}
                className="rounded form-control"
              />
            </th>
            <th scope="column">Tenancy</th>
            <th scope="colum">Rent</th>
            <th scope="column">Deposit</th>
            <th>Inv</th>
            <th>Paid</th>
            <th>Bal</th>
          </tr>
          <tr>
            <th scope="column">House</th>
            <th scope="column">Details</th>
            <th>
              <select
                className="form-control rounded"
                value={Tenancy}
                onChange={(e) => setTenancy(e.target.value)}
              >
                <option value={"all"}>All Tenancy</option>
                <option value={"0"}>Occupied</option>
                <option value={"1"}>Vacant</option>
              </select>
            </th>
            <th>{SearchHouse.reduce((a, b) => +a + +b.rent, 0)}</th>
            <th>{SearchHouse.reduce((a, b) => +a + +b.deposit, 0)}</th>
            <th>{SearchHouse.reduce((a, b) => +a + +b.invoices, 0)}</th>
            <th>{SearchHouse.reduce((a, b) => +a + +b.payments, 0)}</th>
            <th>
              {SearchHouse.reduce((a, b) => +a + +b.invoices, 0) -
                SearchHouse.reduce((a, b) => +a + +b.payments, 0)}
            </th>
            <th colSpan={2}></th>
            <th></th>
          </tr>
        </thead>
        {SearchHouse.length > 0 ? (
          <tbody>
            <PlainList
              list={SearchHouse}
              renderOnScroll
              renderItem={(house) => (
                <HouseDisp
                  setShowModal={setShowModal}
                  ShowModal={ShowModal}
                  house={house}
                  setHouse={setHouse}
                  type={Type}
                  setType={setType}
                  key={house.linker}
                  Buildings={Buildings}
                  setTenantModal={setTenantModal}
                  setTenant={setTenant}
                  tenant={Tenant}
                ></HouseDisp>
              )}
            />
          </tbody>
        ) : (
          <NoHouse></NoHouse>
        )}
      </table>
      <GeneratePdf
        id={"building-houses"}
        name={`${Building?.name}-building-houses`}
      ></GeneratePdf>
      <HouseCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        house={House}
        setHouse={setHouse}
        type={Type}
        Buildings={Buildings}
      ></HouseCrud>
      <TenantCrud
        setShowModal={setTenantModal}
        ShowModal={TenantModal}
        tenant={Tenant}
        setTenant={setTenant}
        type={Type}
      ></TenantCrud>
    </div>
  );
};

export default BuildingHouses;
