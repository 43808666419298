import React, { useState, useEffect } from "react";
import BuildingCrud from "./BuildingCrud";
import { useDispatch, useSelector } from "react-redux";
import {
  PlusCircle,
  Trash,
  PencilSquare,
  HouseAdd,
  BuildingGear,
} from "react-bootstrap-icons";
import NoBuilding from "./NoBuildings";
import { FetchBuildings } from "../../../Network/BuildingApi";
import HouseCrud from "../../Houses/HouseCrud";
import { useNavigate } from "react-router-dom";
import GeneratePdf from "../../../Utils/GeneratePdf";

const Buildings = () => {
  useEffect(() => {
    FetchBuildings(User.token, ActiveCred.instLinker, dispatch, online);
  }, []);

  const [ShowModal, setShowModal] = useState(false);
  const [HouseModal, setHouseModal] = useState(false);
  const [Building, setBuilding] = useState({ name: "" });
  const [Owner, setOwner] = useState("all");
  const [Search, setSearch] = useState("");
  const [Type, setType] = useState("add");
  const [House, setHouse] = useState({ name: "" });

  //redux dispatch
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Buildings = useSelector((state) => state.building.buildings);
  const Owners = useSelector((state) => state.owner.owners);
  const Houses = useSelector((state) => state.house.houses).filter(
    (house) => parseInt(house.deleted) === 0
  );
  const Tenants = useSelector((state) => state.cred.creds).filter(
    (tenant) => parseInt(tenant.deleted) === 0 && parseInt(tenant.tenant) === 1
  );

  const SearchBuilding = Buildings.filter(
    (building) =>
      (building.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
        Search === "") &&
      (Owner === "all" || parseInt(Owner) === parseInt(building.ownerLinker)) &&
      parseInt(building.deleted) === 0
  ).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  const FindOwner = (linker) => {
    let owner = Owners.find(
      (owner) => parseInt(linker) === parseInt(owner.linker)
    );
    return owner || { name: "unknown" };
  };

  return (
    <div>
      <div id={"buildings"}>
        <p className="text-center h6 text-primary text-capitalize">
          {SearchBuilding.length} Buildings
        </p>
        <table className="table table-sm">
          <thead className="thead-dark">
            <tr>
              <th scope="column" colSpan={2}>
                <input
                  type={"text"}
                  placeholder={`Search Building`}
                  value={Search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="rounded form-control"
                />
              </th>
              {Owners.length > 0 ? (
                <th>
                  <select
                    value={Owner}
                    onChange={(e) => setOwner(e.target.value)}
                    className="form-control rounded"
                  >
                    <option value="all">All Owners</option>
                    {Owners.map((owner) => (
                      <option key={owner.linker} value={owner.linker}>
                        {owner.name}
                      </option>
                    ))}
                  </select>
                </th>
              ) : null}
              <th>Houses</th>
              <th>Let</th>
              <th>Vacant</th>
              <td>
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    setShowModal(true);
                    setType("add");
                    setBuilding({ name: "", details: "", ownerLinker: "" });
                  }}
                >
                  <PlusCircle /> Add Building
                </button>
              </td>
            </tr>
          </thead>
          {SearchBuilding.length > 0 ? (
            <tbody>
              {SearchBuilding.map((building, index) => (
                <tr key={index} className="item">
                  <td>{building.name}</td>
                  <td>{building.details}</td>
                  {Owners.length > 0 ? (
                    <td>{FindOwner(building.ownerLinker).name}</td>
                  ) : null}
                  <td>
                    {
                      Houses.filter(
                        (house) =>
                          parseInt(house.buildingLinker) ===
                          parseInt(building.linker)
                      ).length
                    }
                  </td>{" "}
                  <td className="text-success">
                    {
                      Houses.filter(
                        (house) =>
                          parseInt(house.buildingLinker) ===
                            parseInt(building.linker) &&
                          Tenants.some(
                            (tenant) =>
                              parseInt(tenant.houseLinker) ===
                              parseInt(house.linker)
                          )
                      ).length
                    }
                  </td>{" "}
                  <td className="text-primary">
                    {
                      Houses.filter(
                        (house) =>
                          parseInt(house.buildingLinker) ===
                            parseInt(building.linker) &&
                          !Tenants.some(
                            (tenant) =>
                              parseInt(tenant.houseLinker) ===
                              parseInt(house.linker)
                          )
                      ).length
                    }
                  </td>
                  <td>
                    <PencilSquare
                      className="text-info mx-1"
                      onClick={() => {
                        setShowModal(true);
                        setType("edit");
                        setBuilding({ ...building });
                      }}
                      style={{ cursor: "pointer" }}
                    />

                    <Trash
                      className="text-danger mx-1"
                      onClick={() => {
                        setShowModal(true);
                        setType("delete");
                        setBuilding({ ...building });
                      }}
                      style={{ cursor: "pointer" }}
                    />

                    <HouseAdd
                      className="text-primary mx-1"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setHouseModal(true);
                        setType("add");
                        setHouse({
                          linker: Date.now(),
                          trace: Date.now(),
                          name: "",
                          details: "",
                          rent: "",
                          deposit: "",
                          buildingLinker: building.linker,
                          deleted: "0",
                          building,
                        });
                      }}
                    />

                    <BuildingGear
                      className="text-primary mx-1"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`building-houses/${building.linker}`);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <NoBuilding></NoBuilding>
          )}
        </table>
      </div>
      <GeneratePdf id={"buildings"} name={"buildings"}></GeneratePdf>
      <BuildingCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        building={Building}
        setBuilding={setBuilding}
        type={Type}
      ></BuildingCrud>
      <HouseCrud
        setShowModal={setHouseModal}
        ShowModal={HouseModal}
        house={House}
        setHouse={setHouse}
        type={Type}
        Buildings={Buildings}
      ></HouseCrud>
    </div>
  );
};

export default Buildings;
