import React from "react";
import { useParams } from "react-router-dom";

const NoOwner = () => {
  const params = useParams();
  return (
    <tbody>
      <tr>
        <td>No Property Owner</td>

        <td>
          If You are a property manager you add property owners here, if you are
          managing your own properties you can add yourself here
        </td>
      </tr>
    </tbody>
  );
};

export default NoOwner;
