import React from "react";
import {
	Trash,
	PencilSquare,
	PersonAdd,
	Envelope,
} from "react-bootstrap-icons";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CashCoin } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const HouseDisp = ({
	house,
	setShowModal,
	setType,
	setHouse,
	Buildings,
	setTenant,
	setTenantModal,
	setSmsModal,
}) => {
	const navigate = useNavigate();
	const params = useParams();
	const inst = useSelector((state) => state.inst.activeInst);
	const FindBuilding = (linker) => {
		let Building = Buildings.find(
			(building) => parseInt(building.linker) === parseInt(linker)
		);
		return Building || { name: "unknown" };
	};

	return (
		<tr className="item">
			<td>{house.name}</td>
			<td>{house.details}</td>
			{!params.buildingLinker ? (
				<td>{FindBuilding(house.buildingLinker).name}</td>
			) : null}
			<td>
				{house.tenant.linker ? (
					<Dropdown>
						<Dropdown.Toggle
							variant="transparent"
							style={{ margin: 0, padding: 0 }}
							className="text-wrap"
						>
							{house.tenant.firstname} {house.tenant.lastname}{" "}
							{house.tenant.surname}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item>
								<p>
									<strong>Contact :</strong>
									<em>{house.tenant.contact}</em>
								</p>
							</Dropdown.Item>

							<Dropdown.Item>
								<p>
									<strong>Email :</strong>
									<em>{house.tenant.email}</em>
								</p>
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				) : (
					<span className="text-danger">vacant</span>
				)}
			</td>
			<td>{house.rent}</td>
			<td>{house.deposit}</td>
			<td>{house.invoices}</td>
			<td>{house.payments}</td>
			<td>{house.invoices - house.payments}</td>

			<td>
				<Dropdown>
					<Dropdown.Toggle
						variant="transparent"
						style={{ margin: 0, padding: 0 }}
						className="text-primary text-wrap"
					>
						House Update
					</Dropdown.Toggle>
					<Dropdown.Menu>
						<Dropdown.Item
							onClick={() => {
								setShowModal(true);
								setType("edit");
								setHouse({ ...house });
							}}
							style={{ cursor: "pointer" }}
							className="text-info mx-1"
						>
							<PencilSquare />
							Edit House
						</Dropdown.Item>
						<Dropdown.Item
							onClick={() => {
								setShowModal(true);
								setType("delete");
								setHouse({ ...house });
							}}
							style={{ cursor: "pointer" }}
							className="text-danger mx-1"
						>
							<Trash />
							Delete House
						</Dropdown.Item>
						<Dropdown.Item
							onClick={() => {
								window.scroll(0, 0);
								navigate(`house-invoices/${house.linker}`);
							}}
							style={{ cursor: "pointer" }}
							className="text-primary mx-1"
						>
							<CashCoin />
							House Invoice & Pay
						</Dropdown.Item>
						{!house.tenant.linker ? (
							<Dropdown.Item
								onClick={() => {
									setTenantModal(true);
									setType("add");
									setTenant({
										firstname: "",
										lastname: "",
										surname: "",
										contact: "",
										email: "",
										tenant: "",
										buildingLinker: "all",
										houseLinker: house.linker,
										house,
									});
								}}
								style={{ cursor: "pointer" }}
								className="text-primary mx-1"
							>
								<PersonAdd />
								Add Tenant
							</Dropdown.Item>
						) : inst.sub === "Kenya" ? (
							<Dropdown.Item
								onClick={() => {
									setSmsModal(true);
									setTenant({
										...house.tenant,
									});
								}}
								className="text-primary mx-1"
								style={{ cursor: "pointer" }}
							>
								<Envelope />
								Sms Tenant
							</Dropdown.Item>
						) : null}
					</Dropdown.Menu>
				</Dropdown>
			</td>
		</tr>
	);
};

export default HouseDisp;
