import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RegisterLiveCred, updateLiveCred } from "../../Network/CredApi";

const TenantCrud = (props) => {
	//redux dispatch
	const dispatch = useDispatch();

	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const [Loading, setLoading] = useState(false);

	const Tenants = useSelector((state) => state.cred.creds).filter(
		(tenant) => parseInt(tenant.tenant) === 1 && parseInt(tenant.deleted) === 0
	);

	const Houses = useSelector((state) => state.house.houses).filter(
		(house) =>
			parseInt(house.deleted) === 0 &&
			(props.tenant.buildingLinker === "all" ||
				parseInt(props.tenant.buildingLinker) ===
					parseInt(house.buildingLinker)) &&
			!Tenants.some(
				(tenant) => parseInt(tenant.houseLinker) === parseInt(house.linker)
			)
	);

	const Buildings = useSelector((state) => state.building.buildings).filter(
		(building) => parseInt(building.deleted) === 0
	);

	//add tenant
	const AddTenant = async () => {
		let linker = Date.now();

		await RegisterLiveCred(
			{
				...props.tenant,
				linker,
				instLinker: ActiveCred.instLinker,
				credLinker: ActiveCred.linker,
				live: 1,
				status: 0,
				trace: linker,
				deleted: 0,
			},
			"tenant/add",
			User.token,
			dispatch
		);
	};

	//edit tenant
	const EditTenant = async () => {
		let { credLinker, trace, live, deleted, tenant } = props.tenant;

		trace = Date.now();
		credLinker = ActiveCred.linker;
		deleted = props.type === "delete" ? 1 : deleted;
		tenant = props.type === "exit" ? 2 : tenant;

		await updateLiveCred(
			{ ...props.tenant, trace, credLinker, deleted, tenant },
			"tenant/edit",
			User.token,
			dispatch
		);
	};

	const HandleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (props.type === "add") {
			await AddTenant();
		} else {
			await EditTenant();
		}
		setLoading(false);
		props.setShowModal(false);
	};

	return (
		<div>
			<Modal
				show={props.ShowModal}
				onHide={() => props.setShowModal(false)}
				backdrop="static"
				centered
			>
				<Modal.Header closeButton>
					<strong className="text-capitalize text-center">
						{props.type} Tenant: {props.tenant.house?.name}{" "}
					</strong>
					<br />
					{props.type === "delete" ? null : (
						<small style={{ fontSize: "16" }}>Fill Whats Applicable</small>
					)}
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={HandleSubmit}>
						<div className="form-group">
							<div className="row justify-content-center">
								<div className="col-md-6">
									<label className="mb-2 text-capitalize">Surname:</label>
									<input
										className="rounded form-control"
										placeholder={`Enter Surname`}
										value={props.tenant.surname}
										onChange={(e) =>
											props.setTenant({
												...props.tenant,
												surname: e.target.value,
											})
										}
										readOnly={
											props.type === "delete" || props.type === "exit"
												? true
												: false
										}
									/>
									<hr />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-capitalize">FirstName:</label>
									<input
										className="rounded form-control"
										placeholder={`Enter firstname`}
										value={props.tenant.firstname}
										onChange={(e) =>
											props.setTenant({
												...props.tenant,
												firstname: e.target.value,
											})
										}
										readOnly={
											props.type === "delete" || props.type === "exit"
												? true
												: false
										}
									/>
									<hr />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-capitalize">LastName:</label>
									<input
										className="rounded form-control"
										placeholder={`Enter lastname`}
										value={props.tenant.lastname}
										onChange={(e) =>
											props.setTenant({
												...props.tenant,
												lastname: e.target.value,
											})
										}
										readOnly={
											props.type === "delete" || props.type === "exit"
												? true
												: false
										}
									/>
									<hr />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-capitalize">Email:</label>
									<input
										className="rounded form-control"
										placeholder={`Enter email`}
										value={props.tenant.email}
										onChange={(e) =>
											props.setTenant({
												...props.tenant,
												email: e.target.value.toLowerCase(),
											})
										}
										type="email"
										readOnly={
											props.type === "delete" || props.type === "exit"
												? true
												: false
										}
									/>
									<hr />
								</div>
								<div className="col-md-6">
									<label className="mb-2 text-capitalize">Contact:</label>
									<input
										className="rounded form-control"
										placeholder={`Enter contact`}
										value={props.tenant.contact}
										onChange={(e) =>
											props.setTenant({
												...props.tenant,
												contact: e.target.value,
											})
										}
										readOnly={
											props.type === "delete" || props.type === "exit"
												? true
												: false
										}
									/>
									<hr />
								</div>
								{Buildings.length > 0 ? (
									<div className="col-md-6">
										<label className="mb-2 text-capitalize">
											Select Building:
										</label>
										<select
											className="rounded form-control"
											value={props.tenant.buildingLinker}
											onChange={(e) =>
												props.setTenant({
													...props.tenant,
													buildingLinker: e.target.value,
												})
											}
											readOnly={
												props.type === "delete" || props.type === "exit"
													? true
													: false
											}
										>
											<option value={"all"}>All Buildings</option>
											{Buildings.map((building) => (
												<option value={building.linker} key={building.linker}>
													{building.name}
												</option>
											))}
										</select>
										<hr />
									</div>
								) : null}

								<div className="col-md-6">
									<label className="mb-2 text-capitalize">
										Tenant House: {(props.tenant.house || {}).name}
									</label>
									<select
										className="rounded form-control"
										value={props.tenant.houseLinker}
										onChange={(e) =>
											props.setTenant({
												...props.tenant,
												houseLinker: e.target.value,
											})
										}
										readOnly={
											props.type === "delete" || props.type === "exit"
												? true
												: false
										}
										required
									>
										<option value={""}>Select House :</option>
										{props.tenant.house?.linker ? (
											<option value={props.tenant.house?.linker}>
												{props.tenant.house?.name}
											</option>
										) : null}

										{Houses.map((house) => (
											<option value={house.linker} key={house.linker}>
												{house.name}
											</option>
										))}
									</select>
									<hr />
								</div>
							</div>
						</div>
						<hr />
						<div className="d-flex justify-content-around mb-2">
							{Loading ? (
								<span className="spinner-border text-primary"></span>
							) : props.type === "delete" ? (
								<Button variant="danger" type="submit">
									Delete
								</Button>
							) : (
								<Button variant="primary" type="submit">
									Save
								</Button>
							)}
							<Button
								variant="secondary"
								type="button"
								onClick={() => props.setShowModal(false)}
							>
								Cancel
							</Button>
						</div>
					</Form>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default TenantCrud;
