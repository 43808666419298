import React from "react";
import { useParams } from "react-router-dom";

const NoBill = () => {
  const params = useParams();
  return (
    <tbody>
      <tr>
        <td>No Bills</td>

        <td>
          Various Obligations that can be invoiced to a client eg water bill,
          electricity bill, garbage collection
        </td>
      </tr>
    </tbody>
  );
};

export default NoBill;
