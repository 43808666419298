import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FetchNotifications } from "../../Network/NotificationApi";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import NoNotification from "./NoNotification";
import NotificationCrud from "./NotificationCrud";
const Notifications = () => {
  useEffect(() => {
    FetchData();
  }, []);

  const dispatch = useDispatch();

  const [Loading, setLoading] = useState(false);
  const [NoteModal, setNoteModal] = useState(false);
  const [Type, setType] = useState("add");
  const [More, setMore] = useState(0);
  const [NewNote, setNewNote] = useState({ title: "", note: "" });

  const online = useSelector((state) => state.online.online);

  const User = useSelector((state) => state.auth.user);

  const ActiveCred = useSelector((state) => state.cred.activeCred);

  const Notes = useSelector((state) => state.notification.notifications)
    .filter((note) => parseInt(note.deleted) === 0)
    .sort((a, b) => b.linker - a.linker);

  const FetchData = async () => {
    setLoading(true);
    await FetchNotifications(
      User.token,
      ActiveCred.instLinker,
      dispatch,
      online
    );
    setLoading(false);
  };

  const TrimText = (text, length) => {
    if (text.length <= length) {
      return text;
    }
    return text.substr(0, length);
  };

  const NotePermission = async () => {
    try {
      const Perm = await Notification.requestPermission();
    } catch {}
  };
  return (
    <div>
      <div className="d-flex justify-content-around">
        <div>
          {" "}
          <p className="text-center h6 text-primary text-decoration-underline">
            Notifications{" "}
            {Loading ? (
              <span className="spinner-border spinner-border-md"></span>
            ) : null}
          </p>{" "}
        </div>
        {Notification.permission === "default" ? (
          <button
            className="btn btn-outline-success rounded-pill"
            onClick={() => {
              NotePermission();
            }}
          >
            Turn On Notifications
          </button>
        ) : null}
        {ActiveCred.admin == 1 ? (
          <button
            className="btn btn-outline-info rounded-pill"
            onClick={() => {
              setType("add");
              setNewNote({ title: "", note: "" });
              setNoteModal(true);
            }}
          >
            Post A Note
          </button>
        ) : null}
      </div>

      {Notes.length > 0 ? (
        <div className="row justify-content-center">
          {" "}
          {Notes.map((note, index) => (
            <div key={index} className="col-md-6 col-lg-4 col-xl-3">
              <div className="card m-1">
                <div className="card-header">
                  <p className="h6 text-center card-title">{note.title}</p>
                </div>
                <div className="card-body">
                  {More === note.id ? (
                    <p style={{ whiteSpace: "pre-line" }}>{note.note}</p>
                  ) : (
                    <p style={{ whiteSpace: "pre-line" }}>
                      {TrimText(note.note, 50)}{" "}
                      {note.note.length > 50 ? (
                        <span
                          className="btn-link text-primary"
                          style={{ cursor: "pointer" }}
                          onClick={() => setMore(note.id)}
                        >
                          ...more
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                  )}
                </div>
                {ActiveCred.admin == 1 ||
                ActiveCred.linker == note.credLinker ? (
                  <div className="card-footer d-flex justify-content-around">
                    <button
                      className="btn btn-outline-info rounded-pill"
                      onClick={() => {
                        setType("edit");
                        setNewNote(note);
                        setNoteModal(true);
                      }}
                    >
                      <PencilSquare />
                    </button>
                    <button
                      className="btn btn-outline-danger rounded-pill"
                      onClick={() => {
                        setType("delete");
                        setNewNote(note);
                        setNoteModal(true);
                      }}
                    >
                      <Trash />
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <NoNotification></NoNotification>
      )}
      <NotificationCrud
        setShowModal={setNoteModal}
        ShowModal={NoteModal}
        notification={NewNote}
        setNotification={setNewNote}
        type={Type}
      ></NotificationCrud>
    </div>
  );
};

export default Notifications;
