import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addLiveHouse, updateLiveHouse } from "../../Network/HouseApi";

const HouseCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const [Loading, setLoading] = useState(false);

  //edit house
  const EditHouse = async () => {
    let { credLinker, trace, live, deleted } = props.house;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveHouse(
      { ...props.house, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const SaveHouse = async () => {
    let trace = Date.now();

    await addLiveHouse(
      {
        ...props.house,
        instLinker: ActiveCred.instLinker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: 0,
        trace,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add") {
      await SaveHouse();
    } else {
      await EditHouse();
    }
    setLoading(false);

    props.setShowModal(false);
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">
            {props.type} House {props.house.building?.name}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          {props.Buildings.length > 0 && !props.house.building ? (
            <div className="form-group">
              <label className="mb-2 text-capitalize">House Building:</label>
              <select
                className="rounded form-control"
                value={props.house.buildingLinker}
                onChange={(e) =>
                  props.setHouse({
                    ...props.house,
                    buildingLinker: e.target.value,
                  })
                }
                readOnly={props.type === "delete" ? true : false}
              >
                <option value={""}>Select House Building</option>
                {props.Buildings.map((building, index) => (
                  <option key={index} value={building.linker}>
                    {building.name}
                  </option>
                ))}
              </select>
              <hr />
            </div>
          ) : null}
          <div className="form-group">
            <label className="mb-2 text-capitalize"> House Number:</label>
            <input
              className="rounded form-control"
              placeholder={`enter house number`}
              value={props.house.name}
              onChange={(e) =>
                props.setHouse({
                  ...props.house,
                  name: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="form-group">
            <label className="mb-2 text-capitalize">House Details:</label>
            <textarea
              className="rounded form-control"
              placeholder={`enter house details`}
              value={props.house.details}
              onChange={(e) =>
                props.setHouse({
                  ...props.house,
                  details: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>

          <div className="form-group">
            <label className="mb-2 text-capitalize">House Rent Amount:</label>
            <input
              className="rounded form-control"
              placeholder={`enter house rent`}
              value={props.house.rent}
              onChange={(e) =>
                props.setHouse({
                  ...props.house,
                  rent: e.target.value,
                })
              }
              type="number"
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="form-group">
            <label className="mb-2 text-capitalize">
              House Deposit Amount:
            </label>
            <input
              className="rounded form-control"
              placeholder={`enter house deposit`}
              value={props.house.deposit}
              onChange={(e) =>
                props.setHouse({
                  ...props.house,
                  deposit: e.target.value,
                })
              }
              type="number"
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : props.type === "delete" ? (
              <Button variant="danger" type="submit">
                Delete
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default HouseCrud;
